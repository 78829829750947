import {
  Box,
  Fieldset,
  FormControl,
  Grid,
  GridCol,
  Input,
  Legend,
  Select,
  SelectGroup,
  Textarea,
} from '@flixbus/honeycomb-react';
import React, { useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { SHIRT_SIZES } from '../../../../Constants';
import { IGNORED_FIELDS, QUILL_MODULES } from '../../Constants';
import { Assignees } from '../assignees/Assignees';
import Dependencies from '../dependencies/dependencies';
import { Kpi } from '../kpi/Kpi';
import { Milestones } from '../milestones/Milestones';
import { Quarters } from '../quarters/Quarters';
import { Tags } from '../tags/Tags';

import styles from './EpicForm.module.scss';

const EpicFormComp = ({ epic, onEpicChange, filters, people, searchPeople }) => {
  const { priority: priorities = [], status: statuses = [], team: teams = [], timeframe: timeframes = [] } = filters;
  const teamKpis = useMemo(() => {
    const team = filters.team.find((t) => t.id === parseInt(epic.teams, 10));
    return (team && team.kpis) || [];
  }, [epic.teams, filters.team]);
  const epicTeams = Array.isArray(epic.teams) ? epic.teams : epic.teams.length > 0 ? [parseInt(epic.teams)] : [];

  const onInputChange = (e) => {
    const field = e.target.name || e.target.id;
    let value = e.target.value;

    if (IGNORED_FIELDS.includes(field) || !field) return;

    if (field === 'teams') {
      const teamId = parseInt(value, 10);
      if (teamId === 0) {
        return alert('You need to choose a team. You cannot select "All Teams" as team');
      }
      const team = filters.team.find((t) => t.id === teamId);
      if (team) {
        onEpicChange('kpis', []);
        onEpicChange(field, [teamId]);
      }
    } else {
      onEpicChange(field, value);
    }
  };

  const onQuillChange = (value) => onEpicChange('notes', value);

  const onMilestonesChange = (milestones) => onEpicChange('milestones', milestones);

  const onKpiChanged = (kpi) => onEpicChange('kpis', kpi);

  const onTagsChange = (tags) => onEpicChange('tags', tags);

  const onAssigneesChange = (assignees) => onEpicChange('assignees', assignees);

  const onDependenciesChange = (dependencies) => onEpicChange('dependencies', dependencies);

  const onTimeFramesChanged = (timeframes) => onEpicChange('timeframes', timeframes);

  const teamOptions = teams.map((team) => ({
    label: team.name,
    value: team.id,
    displayValue: team.name,
  }));

  const tShirtOptions = Object.values(SHIRT_SIZES).map((item) => ({
    label: item,
    value: item,
    id: `${item}-Size`,
    name: 'size',
    checked: epic.size === item,
    onChange: onInputChange,
  }));

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Fieldset>
        <Box>
          <Grid>
            <GridCol size={6}>
              <FormControl>
                <Input
                  id='title'
                  label='Title'
                  value={epic.title}
                  onChange={onInputChange}
                  maxLength='200'
                  required
                  valid={!!epic.title}
                />
              </FormControl>
              <FormControl>
                <Textarea
                  id='description'
                  name='description'
                  label='Description (optional)'
                  maxLength={445}
                  value={epic.description}
                  onChange={onInputChange}
                  valid={epic.description ? /\S/.test(epic.description) : true}
                  extraClasses={styles.descriptionField}
                />
              </FormControl>
              <Input
                id='jira'
                label='Jira link (optional)'
                value={epic.jira || ''}
                onChange={onInputChange}
                placeholder='Paste URL here...'
                maxLength={200}
                type='url'
              />
            </GridCol>

            <GridCol size={6}>
              <FormControl>
                <SelectGroup id='size' label='T-Shirt size' options={tShirtOptions} multi={false} />
              </FormControl>

              <FormControl>
                <label htmlFor='notes'>Notes (optional)</label>
                <ReactQuill
                  id='notes'
                  name='notes'
                  modules={QUILL_MODULES}
                  value={epic.notes}
                  onChange={onQuillChange}
                  className={styles.quillDescription}
                  required
                />
              </FormControl>

              <Tags tags={epic.tags} setTags={onTagsChange} />
            </GridCol>
          </Grid>
        </Box>
      </Fieldset>

      <Grid>
        <GridCol size={6}>
          <Fieldset extraClasses={styles.boxFieldSet}>
            <Legend>Team</Legend>
            <Box>
              <FormControl>
                <Select
                  id='teams'
                  label='Team Name'
                  options={teamOptions}
                  value={epicTeams[0]}
                  onChange={onInputChange}
                  valid={epicTeams.length > 0 && epicTeams[0] !== 0}
                  required
                  {...(epicTeams[0] === 0 && {
                    infoError: 'You need to choose a team. You cannot select "All Teams".',
                  })}
                />
              </FormControl>

              {teamKpis.length > 0 && <Kpi teamKpis={teamKpis} epicKpis={epic.kpis} setKpis={onKpiChanged} />}
            </Box>
          </Fieldset>
        </GridCol>
        <GridCol size={6}>
          <Fieldset extraClasses={styles.boxFieldSet}>
            <Legend>Assignees (optional)</Legend>
            <Assignees
              setAssignees={onAssigneesChange}
              people={people}
              searchPeople={searchPeople}
              assigneeList={epic.assignees}
            />
          </Fieldset>
        </GridCol>
      </Grid>

      {/*<Fieldset extraClasses={styles.boxFieldSet}>
        <Legend>Dependencies (optional)</Legend>
        <Box>
          <Dependencies
            dependencies={epic.dependencies}
            setDependencies={onDependenciesChange}
            teams={teams}
          />
        </Box>
      </Fieldset>*/}

      <Grid>
        <GridCol size={6}>
          <Fieldset extraClasses={styles.boxFieldSet}>
            <Legend>Quarters</Legend>
            <Quarters
              priorities={priorities}
              statuses={statuses}
              quarters={epic.timeframes}
              timeframes={timeframes}
              setQuarters={onTimeFramesChanged}
            />
          </Fieldset>
        </GridCol>
        <GridCol size={6}>
          <Fieldset extraClasses={styles.boxFieldSet}>
            <Legend>Milestones</Legend>
            <Milestones milestones={epic.milestones} setMilestones={onMilestonesChange} />
          </Fieldset>
        </GridCol>
      </Grid>
    </form>
  );
};

export const EpicForm = React.memo(EpicFormComp);
