import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { APP_ACTIONS } from '../../../actions';
import { HeaderUserWidget } from '@flixbus/honeycomb-react';

const HeaderProfile = ({
  accountInfo = { account: { name: 'Guest' } },
  profile,
  actions,
  isLoggedIn,
  login,
  logout,
}) => {
  useEffect(() => {
    accountInfo.jwtAccessToken && actions.triggerSetProfileData(accountInfo);
  }, [accountInfo, actions]);

  const navigation = isLoggedIn
    ? [
        {
          content: 'Logout',
          href: '#',
          onClick: logout,
        },
      ]
    : [
        {
          content: 'Login',
          href: '#',
          onClick: login,
        },
      ];

  return (
    <HeaderUserWidget
      src={profile.photo}
      alt={`Profile of ${accountInfo.account.name}`}
      text={accountInfo.account.name}
      navigation={navigation}
    />
  );
};

const mapStateToProps = (state) => ({ profile: state.app.profile });

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ triggerSetProfileData: APP_ACTIONS.triggerSetProfileData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderProfile));
