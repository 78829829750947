import { Box, Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';
import React from 'react';

import styles from './DependencyReport.module.scss';

const DependencyReportSkeletonComp: React.FC = () => {
  return (
    <Grid>
      {[0, 1, 2].map((i) => {
        return (
          <GridCol size={12} md={6} lg={4} key={i}>
            <Box extraClasses={styles.boxContainer} key={i}>
              <div className={styles.heading}>
                <Skeleton width="md" height="md" />
                <Skeleton width="xs" height="md" />
              </div>
              <div className={styles.iconContainer}>
                <Skeleton width="xs" height="lg" />
              </div>
            </Box>
          </GridCol>
        );
      })}
    </Grid>
  );
};

export const DependencyReportSkeleton = React.memo(DependencyReportSkeletonComp);
