import React from 'react';

import styles from './Kpis.module.scss';

const KpiRow = ({ kpi }) => {
  const { emoji, name, initial_indicator_val, current_indicator_val, target_indicator_val } = kpi;
  const divider = target_indicator_val - initial_indicator_val || 1; // prevent to divide by 0
  const dividend = current_indicator_val - initial_indicator_val;
  const percentage = divider === dividend ? 100 : Math.round((dividend * 100) / divider);

  const bar = {
    color: percentage >= 0 ? '#73D700' : '#FF5700',
    width: percentage >= 0 ? `${percentage}%` : '100%',
  };

  return (
    <div className={styles.row}>
      <div className={styles.nameWrapper}>
        <span>{emoji}</span>
        <span className={styles.name}>{name}</span>
      </div>
      <div className={styles.progress}>
        <div className={[styles.progressVal, styles.right].join(' ')}>{initial_indicator_val}</div>
        <div className={styles.progressCenter}>
          <span>{current_indicator_val}</span>
          <div
            style={{ background: `linear-gradient(90deg, ${bar.color} ${bar.width}, #E1E1E1 0%)` }}
            className={styles.progressBar}
          />
        </div>
        <div className={styles.progressVal}>{target_indicator_val}</div>
      </div>
    </div>
  );
};

export default KpiRow;
