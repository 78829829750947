import { createActions } from 'redux-actions';
import { API_ACTION_TYPES, INITIATIVE_ACTION_TYPES } from '../../config';
import { API_ENDPOINTS, DRAGGING_ALLOWED_KEYS } from '../Constants';
import { deserializeInitiative, filterInitiative, filterParams } from './Helpers';

export default createActions({
  [INITIATIVE_ACTION_TYPES.PREFIX]: {
    [INITIATIVE_ACTION_TYPES.TRIGGER_FETCH_INITIATIVES]: (params) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.getRoadMap(params.timeframe),
      method: API_ACTION_TYPES.GET_METHOD,
      params: filterParams(params),
      requestType: INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_STARTED,
      responses: {
        success: { type: INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_SUCCESS },
        error: { type: INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_FAILURE },
      },
    }),
    [INITIATIVE_ACTION_TYPES.TRIGGER_CREATE_INITIATIVE]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.createInitiative(),
      method: API_ACTION_TYPES.POST_METHOD,
      body: filterInitiative(deserializeInitiative(payload)),
      requestType: INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_STARTED,
      responses: {
        success: {
          type: INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_SUCCESS,
          meta: payload,
        },
        error: { type: INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_FAILURE },
      },
    }),
    [INITIATIVE_ACTION_TYPES.TRIGGER_EDIT_INITIATIVE]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.editInitiative(payload.id),
      method: API_ACTION_TYPES.PUT_METHOD,
      body: filterInitiative(deserializeInitiative(payload)),
      requestType: INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_STARTED,
      responses: {
        success: {
          type: INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_SUCCESS,
          meta: payload,
        },
        error: {
          type: INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_FAILURE,
        },
      },
    }),
    [INITIATIVE_ACTION_TYPES.TRIGGER_DRAG_INITIATIVE]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.dragInitiative(payload.id, payload.timeframe),
      method: API_ACTION_TYPES.PUT_METHOD,
      body: filterInitiative(deserializeInitiative(payload), DRAGGING_ALLOWED_KEYS),
      requestType: INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_STARTED,
      responses: {
        success: {
          type: INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_SUCCESS,
          meta: payload,
        },
        error: {
          type: INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_FAILURE,
        },
      },
    }),
    [INITIATIVE_ACTION_TYPES.TRIGGER_DELETE_INITIATIVE]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.deleteInitiative(payload),
      method: API_ACTION_TYPES.DELETE_METHOD,
      requestType: INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_STARTED,
      responses: {
        success: { type: INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_SUCCESS },
        error: { type: INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_FAILURE },
      },
    }),
  },
});
