import React from 'react';
import { Fieldset, SelectGroup } from '@flixbus/honeycomb-react';

const KpiPicker = ({ teamKpis, setKpis, epicKpis = [] }) => {
  const onKpiSelect = (e) => {
    const kpiId = parseInt(e.target.id, 10);
    if (e.target.checked) {
      setKpis([...epicKpis, kpiId]);
    } else {
      console.log(epicKpis, kpiId);
      setKpis(epicKpis.filter((k) => k && k !== kpiId));
    }
  };

  const kpiOptions = [...teamKpis].map((item) => ({
    id: item.id,
    name: item.name,
    label: item.emoji,
    value: item.id,
    title: item.name,
    defaultChecked: epicKpis.some((k) => k === item.id),
    onClick: onKpiSelect,
  }));

  return (
    <Fieldset>
      <SelectGroup id={'kpis'} multi={true} label='Select KPIs' options={kpiOptions} onChange={onKpiSelect} />
    </Fieldset>
  );
};

export const Kpi = React.memo(KpiPicker);
