import { Grid, GridCol, Select } from '@flixbus/honeycomb-react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTeamsOfDepartment } from '../../../../utils/getTeamsOfDepartment';
import { dependencyFiltersSetAction } from '../../../actions/appGlobals.actions';
import { useFiltersListQuery } from '../../../data/useFiltersListQuery';
import { getDependencyFilters } from '../../../selectors/appGlobals.selectors';

import { DepartmentTeamPickerSkeleton } from './DepartmentTeamPickerSkeleton';

import styles from './DependencyTeamPicker.module.scss';

const DepartmentTeamPickerComp: React.FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getDependencyFilters);
  const { department, team } = filters;
  const { isLoading, error, data } = useFiltersListQuery();
  const departmentOptions = (data?.department || []).map((item) => ({
    label: item.name,
    value: item.id,
    displayValue: item.name,
  }));
  const departmentTeams = getTeamsOfDepartment(department, data?.team || []);
  const teamOptions = departmentTeams.map((item) => ({
    label: item.name,
    value: item.id,
    displayValue: item.name,
  }));
  teamOptions.unshift({
    displayValue: 'All teams',
    label: 'All teams',
    value: 0,
  });

  useEffect(() => {
    if (data && data.department.length > 0 && !filters.department) {
      const department = data.department[0].id;
      dispatch(
        dependencyFiltersSetAction({
          ...filters,
          department,
          team: 0,
        })
      );
    }
  }, [dispatch, data, filters]);

  const onDepartmentSelected = useCallback(
    (v: React.ChangeEvent<HTMLSelectElement>) => {
      const department = parseInt(v.target.value, 10);
      const payload = {
        ...filters,
        department,
        team: 0,
      };
      dispatch(dependencyFiltersSetAction(payload));
    },
    [dispatch, filters]
  );

  const onTeamSelected = useCallback(
    (v: React.ChangeEvent<HTMLSelectElement>) => {
      const team = parseInt(v.target.value, 10);
      const payload = {
        ...filters,
        team,
      };
      dispatch(dependencyFiltersSetAction(payload));
    },
    [dispatch, filters]
  );

  if (isLoading) {
    return <DepartmentTeamPickerSkeleton />;
  } else if (!!error) {
    return <span>{error.message}</span>;
  }

  return (
    <Grid align={'center'}>
      <GridCol size={6}>
        <Select
          extraClasses={styles.select}
          id="dependency-department"
          aria-label="Department"
          placeholder={'Choose the department'}
          options={departmentOptions}
          onChange={onDepartmentSelected}
          value={department}
        />
      </GridCol>
      <GridCol size={6}>
        <Select
          extraClasses={styles.select}
          id="dependency-team"
          aria-label="Team"
          placeholder={'Choose the team'}
          options={teamOptions}
          onChange={onTeamSelected}
          value={team}
        />
      </GridCol>
    </Grid>
  );
};

export const DepartmentTeamPicker = React.memo(DepartmentTeamPickerComp);
