import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';

import { cleanSearchString, searchInitiatives } from '../../../Helpers';
import { getInitiativesFromPriorityId } from '../../../selectors/Initiative.selectors';
import { serializeInitiatives } from '../../../reducers/Helpers';
import { getSelectedFiltersForCurrentView } from '../../../selectors';
import { ITEM_TYPES } from '../Constants';
import RoadmapCell from '../roadmap-cell';
import { GridCol } from '@flixbus/honeycomb-react';

import styles from './RoadmapColumn.module.scss';

const RoadmapColumn = ({
  editInitiative,
  initiatives,
  priority,
  selectedFilters,
  showInitiativeViewPanel,
  createdBy,
}) => {
  const [localInits, setLocalInits] = useState(serializeInitiatives(initiatives));
  const filteredInits = searchInitiatives(initiatives, cleanSearchString(''));
  const [filteredLocalInits, setFilteredLocalInits] = useState(filteredInits);
  const timeframe = selectedFilters.timeframe;

  useEffect(() => {
    setLocalInits(serializeInitiatives(initiatives));
  }, [initiatives]);

  useEffect(() => {
    const filteredInits = selectedFilters.search
      ? searchInitiatives(localInits, cleanSearchString(selectedFilters.search))
      : localInits;
    setFilteredLocalInits(filteredInits);
  }, [localInits, selectedFilters.search]);

  // this will be triggered in the dropped column
  const [, drop] = useDrop({
    accept: ITEM_TYPES.INITIATIVE,
    drop: (item, monitor) => {
      const { initiative, cellColumn, cellId, droppedIndex, droppedColumn, droppedOverCell } = item;
      const indexChanged = cellId !== droppedOverCell;
      const columnChanged = cellColumn !== droppedColumn;

      if (columnChanged) {
        addInitiative(droppedIndex, initiative);
      } else if (indexChanged) {
        moveInitiative(cellId, droppedOverCell, initiative);
      }

      return {
        droppedColumn,
      };
    },
    hover: (item, monitor) => {
      // Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
      // but it's good here for the sake of performance to avoid expensive index searches.
      monitor.getItem().droppedIndex = localInits.length;
      monitor.getItem().droppedColumn = priority;
    },
  });

  const addInitiative = (position, initiative) => {
    const payload = { ...initiative, position, priority, timeframe, createdBy };
    editInitiative(payload);

    const newList = [...localInits];
    if (newList.length) {
      newList.splice(position, 0, payload);
    } else {
      newList.push(payload);
    }
    setLocalInits(newList);
  };

  const removeInitiative = (initiative) => {
    setLocalInits(localInits.filter(({ id }) => id !== initiative.id));
  };

  const moveInitiative = (cellId, droppedOverCell, initiative) => {
    const position = localInits.findIndex((init) => init.id === droppedOverCell);
    const cellIndex = localInits.findIndex((init) => init.id === cellId);

    if (position >= 0 && cellIndex >= 0) {
      editInitiative({ ...initiative, position, timeframe, createdBy });

      const list = [...localInits];
      list.splice(cellIndex, 1);
      list.splice(position, 0, initiative);
      setLocalInits([...list]);
    }
  };
  // @todo adding an additional wrapper inside the GridCol since HC-React does not allow for refs in columns
  return (
    <GridCol size={3}>
      <div ref={drop} className={styles.dropZone}>
        {filteredLocalInits.map((initiative, index) => {
          return (
            <RoadmapCell
              key={index}
              priority={priority}
              initiative={initiative}
              cellId={initiative.id}
              cellIndex={index}
              cellColumn={priority}
              selectedFilters={selectedFilters}
              removeInitiative={removeInitiative}
              showInitiativeViewPanel={showInitiativeViewPanel}
            />
          );
        })}
      </div>
    </GridCol>
  );
};

const RoadmapColumnContainer = ({ priority, ...rest }) => {
  const initiatives = useSelector((state) => getInitiativesFromPriorityId(state, { priorityId: priority }));
  const selectedFilters = useSelector(getSelectedFiltersForCurrentView);

  return <RoadmapColumn initiatives={initiatives} selectedFilters={selectedFilters} priority={priority} {...rest} />;
};

export default React.memo(RoadmapColumnContainer);
