import { Button, Heading } from '@flixbus/honeycomb-react';
import React from 'react';

import WelcomeImage from '../../assets/imgs/welcome.jpg';

import styles from './Login.module.scss';

const Login: React.FC<any> = ({ login }) => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginContent}>
        <Heading size={1}>Welcome to Roadmaps</Heading>
        <img src={WelcomeImage} className={styles.loginImage} alt="Welcome to FlixBus" />
        <div className={styles.borderLine} />
        <div className={styles.loginText}>
          <p>Dear visitor,</p>
          <p>
            You need <b>to login in</b> order to continue.
          </p>
          <p>
            Please click in the button below to login to the application. Once you click the button a confirmation page
            will open
          </p>
          <p>
            <Button appearance="secondary" onClick={login}>
              Login to Roadmaps
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
