import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { FILTER_ACTIONS, INITIATIVE_ACTIONS } from '../actions';
import { FILTER_ACTION_TYPES, INITIATIVE_ACTION_TYPES } from '../../config';
import { push } from 'react-router-redux';
import { APP_PAGES } from '../Constants';
import { getSelectedFiltersForCurrentView } from '../selectors';

export function* syncPayload({ payload }) {
  yield put(payload);
}

function* goHome() {
  const { timeframe, team, ...selectedFilters } = yield select(getSelectedFiltersForCurrentView);

  yield put(INITIATIVE_ACTIONS.triggerFetchInitiatives({ timeframe, team, ...selectedFilters }).payload);

  yield put(push(APP_PAGES.home.path(timeframe, team)));
}

export default function* initiativeSaga() {
  yield takeEvery(INITIATIVE_ACTIONS.triggerCreateInitiative.toString(), syncPayload);
  yield takeEvery(INITIATIVE_ACTIONS.triggerEditInitiative.toString(), syncPayload);
  yield takeEvery(INITIATIVE_ACTIONS.triggerDragInitiative.toString(), syncPayload);
  yield takeEvery(INITIATIVE_ACTIONS.triggerDeleteInitiative.toString(), syncPayload);

  yield takeLatest(FILTER_ACTIONS.triggerFetchInitiative.toString(), syncPayload);
  yield takeLatest(INITIATIVE_ACTIONS.triggerFetchInitiatives.toString(), syncPayload);

  yield takeLatest(INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_SUCCESS, goHome);
  yield takeLatest(INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_SUCCESS, goHome);
  yield takeLatest(INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_SUCCESS, goHome);

  yield takeEvery(FILTER_ACTION_TYPES.FETCH_INITIATIVE_FAILURE, goHome);
}
