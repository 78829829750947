import { all, put, takeLatest } from 'redux-saga/effects';
import { APP_ACTIONS } from '../actions';
import { DeviceInformation } from '../../utils';

export function* setDeviceDetails({ payload }) {
  const deviceData = DeviceInformation();
  // noinspection JSCheckFunctionSignatures
  yield put({ ...payload, deviceData });
}

export function* takeAction({ payload }) {
  yield put(payload);
}

export default function* appSaga() {
  yield all([
    takeLatest(APP_ACTIONS.triggerSetDeviceData.toString(), setDeviceDetails),
    takeLatest(APP_ACTIONS.triggerSetAuthData.toString(), takeAction),
    takeLatest(APP_ACTIONS.triggerSetProfileData.toString(), takeAction),
    takeLatest(APP_ACTIONS.triggerSetPage.toString(), takeAction),
    takeLatest(APP_ACTIONS.triggerShowDeletePopup.toString(), takeAction),
    takeLatest(APP_ACTIONS.triggerHideDeletePopup.toString(), takeAction),
  ]);
}
