import React from 'react';
import styles from './LoadingSpinner.module.scss';
import { Spinner } from '@flixbus/honeycomb-react';

const LoadingSpinner = () => (
  <div className={styles.fullContainer}>
    <Spinner size={'lg'} />
  </div>
);

export default LoadingSpinner;
