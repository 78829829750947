import { createActions } from 'redux-actions';
import { API_ACTION_TYPES, FILTER_ACTION_TYPES } from '../../config';
import { API_ENDPOINTS, GRAPH_ENDPOINTS } from '../Constants';

export default createActions({
  [FILTER_ACTION_TYPES.PREFIX]: {
    [FILTER_ACTION_TYPES.TRIGGER_FETCH_FILTERS]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.getFilters(),
      method: API_ACTION_TYPES.GET_METHOD,
      payload,
      requestType: FILTER_ACTION_TYPES.FETCH_FILTERS_STARTED,
      responses: {
        success: { type: FILTER_ACTION_TYPES.FETCH_FILTERS_SUCCESS },
        error: { type: FILTER_ACTION_TYPES.FETCH_FILTERS_FAILURE },
      },
    }),
    [FILTER_ACTION_TYPES.TRIGGER_FETCH_INITIATIVE]: (params) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: API_ENDPOINTS.getInitiative(params.id),
      method: API_ACTION_TYPES.GET_METHOD,
      requestType: FILTER_ACTION_TYPES.FETCH_INITIATIVE_STARTED,
      responses: {
        success: { type: FILTER_ACTION_TYPES.FETCH_INITIATIVE_SUCCESS },
        error: { type: FILTER_ACTION_TYPES.FETCH_INITIATIVE_FAILURE },
      },
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SEARCH_PEOPLE]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: GRAPH_ENDPOINTS.getPeople(payload.query),
      method: API_ACTION_TYPES.GET_METHOD,
      payload,
      requestType: FILTER_ACTION_TYPES.SEARCH_PEOPLE_STARTED,
      responses: {
        success: { type: FILTER_ACTION_TYPES.SEARCH_PEOPLE_SUCCESS },
        error: { type: FILTER_ACTION_TYPES.SEARCH_PEOPLE_FAILURE },
      },
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_CURRENT_VIEW]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_CURRENT_VIEW,
      view: payload,
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_FILTER]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_FILTER,
      payload,
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_CREATE_INITIATIVE_FIELD]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_CREATE_INITIATIVE_FIELD,
      payload,
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_INITIATIVE]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_INITIATIVE,
      payload,
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_PEOPLE]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_PEOPLE,
      payload,
    }),
    [FILTER_ACTION_TYPES.TRIGGER_SET_FILTERS]: (payload) => ({
      type: FILTER_ACTION_TYPES.SET_FILTERS,
      payload,
    }),
  },
});
