import { Heading, Tag, Text } from '@flixbus/honeycomb-react';
import React from 'react';

const InitiativeDescription = ({styles, description, notes, tags}) => {
  const tagsList = (tags || []).slice(0, 4).sort((a, b) => b.length - a.length);

  return (
    <>
      <Text dangerouslySetInnerHTML={{ __html: (description || '').replace(/(?:\r\n|\r|\n)/g, '<br>') }} />

      {tagsList.length > 0 && (
        tagsList.map((a, index) => (
          <Tag extraClasses={styles.tag} key={index}>{a.toLowerCase()}</Tag>
        ))
      )}

      {notes.length > 0 && (
        <>
          <Heading size={3}>Notes:</Heading>
          <Text Elem='div' dangerouslySetInnerHTML={{ __html: notes }} extraClasses={styles.notes} />
        </>
      )}
    </>
  );
};

export default InitiativeDescription;
