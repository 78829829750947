import React from 'react';
import Rollbar from 'rollbar';

export const IS_DEVELOPMENT_MODE = '_self' in React.createElement('div');

const Logging = new Rollbar({
  accessToken: '62290dcd33974a79858466b7ad148878',
  captureUncaught: true,
  captureUnhandledRejections: true,
  reportLevel: 'warning',
  enabled: !IS_DEVELOPMENT_MODE,
  captureIp: true,
  verbose: false,
});

export const loggingCallback = (data, type) => Logging[(type || '').toString().toLowerCase()](data);

window.onerror = (msg, url, lineNo, columnNo, error) => loggingCallback(error, 'error');

export default Logging;
