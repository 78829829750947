import { DataTable, Heading } from '@flixbus/honeycomb-react';
import React from 'react';
import { DEFAULT_FILTER_TYPES } from '../../../Constants';

const QuarterDetails = ({styles, timeframes, priorities, statuses}) => {
  const headers = {cells: ['Quarter', 'Priority', 'Status']};

  const quarterRows = timeframes.map((frame) => {
    const priority =
      priorities.find((priority) => parseInt(priority.id) === frame.priority) || DEFAULT_FILTER_TYPES.priority;

    const status = statuses.find((status) => parseInt(status.id) === frame.status) || DEFAULT_FILTER_TYPES.status;

    return {cells: [frame.timeframe.name, priority.name.toString(), status.name.toLowerCase()]};
  });

  return (
    <div className={styles.panelTable}>
      <Heading size={3}>Quarters:</Heading>
      <DataTable small rows={quarterRows} headers={headers} />
    </div>
  )
}

export default QuarterDetails;
