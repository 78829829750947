import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { FILTER_ACTION_TYPES } from '../../config';
import { DEFAULT_FILTER_STATE } from '../Constants';
import { parseInitiativeField, parseSelectedFieldsIntoSession, serializeInitiative } from './Helpers';
import { VALID_FILTERS_FOR_SET } from './Constants';
import pick from 'lodash/pick';

export default handleActions(
  new Map([
    [
      FILTER_ACTION_TYPES.FETCH_FILTERS_STARTED,
      (state) =>
        update(state, {
          loadingFilters: { $set: true },
          error: { $set: null },
        }),
    ],
    [
      FILTER_ACTION_TYPES.FETCH_FILTERS_SUCCESS,
      (state, { data }) => {
        const { timeframe, team, department, description } = parseSelectedFieldsIntoSession(data, state);

        return update(state, {
          filters: {
            $set: {
              ...data,
              status: Object.keys(data.status).map((key) => ({ id: key, name: data.status[key] })),
              priority: Object.keys(data.priority).map((key) => ({ id: key, name: data.priority[key] })),
              department: data.department.sort((a, b) => a.name.localeCompare(b.name)),
              team: data.team.sort((a, b) => a.name.localeCompare(b.name)),
            },
          },
          selectedFilters: {
            $set: {
              ...DEFAULT_FILTER_STATE.selectedFilters,
              [state.currentView]: {
                ...DEFAULT_FILTER_STATE.selectedFilters[state.currentView],
                timeframe: timeframe.slug,
                department: department.id,
                team: team.id,
                toggles: { description },
              },
            },
          },
          initiative: {
            $set: {
              ...DEFAULT_FILTER_STATE.initiative,
              teams: [team.id],
            },
          },
          loadingFilters: { $set: false },
          error: { $set: null },
        });
      },
    ],
    [
      FILTER_ACTION_TYPES.FETCH_FILTERS_FAILURE,
      (state, { meta }) =>
        update(state, {
          filters: { $set: DEFAULT_FILTER_STATE.filters },
          loadingFilters: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
    [
      FILTER_ACTION_TYPES.FETCH_INITIATIVE_STARTED,
      (state) =>
        update(state, {
          error: { $set: null },
        }),
    ],
    [
      FILTER_ACTION_TYPES.FETCH_INITIATIVE_SUCCESS,
      (state, { data }) =>
        update(state, {
          initiative: {
            $set: {
              ...serializeInitiative(data, state.selectedFilters[state.currentView].timeframe),
              timeframe:
                data['timeframes'].length > 0
                  ? data['timeframes'][0].timeframe.slug
                  : state.selectedFilters[state.currentView].timeframe,
              timeframes: data.timeframes,
              teams: data.teams.map((team) => parseInt(team.id)),
            },
          },
          error: { $set: null },
        }),
    ],
    [
      FILTER_ACTION_TYPES.FETCH_INITIATIVE_FAILURE,
      (state, { meta }) =>
        update(state, {
          initiative: { $set: DEFAULT_FILTER_STATE.initiative },
          error: { $set: meta.statusText },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_CURRENT_VIEW,
      (state, { view }) =>
        update(state, {
          currentView: { $set: view },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_FILTER,
      (state, { payload: { field, value } }) =>
        update(state, {
          selectedFilters: {
            [state.currentView]: {
              [field]: { $set: parseInitiativeField({ field, value }) },
            },
          },
          initiative: {
            $set: {
              // @TODO: Proper Iteration between valid fields
              ...DEFAULT_FILTER_STATE.initiative,
              timeframe: field === 'timeframe' ? value : state.selectedFilters[state.currentView].timeframe,
              departments: [field === 'department' ? value : state.selectedFilters[state.currentView].department],
              teams: [field === 'team' ? value : state.selectedFilters[state.currentView].team],
            },
          },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_FILTERS,
      (state, { payload }) =>
        update(state, {
          selectedFilters: {
            [state.currentView]: {
              $set: {
                ...state.selectedFilters[state.currentView],
                ...pick(payload, VALID_FILTERS_FOR_SET),
              },
            },
          },
          initiative: {
            // @TODO: Proper Iteration between valid fields
            $set: {
              ...DEFAULT_FILTER_STATE.initiative,
              departments: [
                payload.department !== undefined
                  ? payload.department
                  : state.selectedFilters[state.currentView].department,
              ],
              teams: [payload.team !== undefined ? payload.team : state.selectedFilters[state.currentView].team],
            },
          },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_INITIATIVE,
      (state, { payload }) =>
        update(state, {
          initiative: {
            $set: {
              ...payload,
              teams: payload.teams.map((team) => parseInt(team.id || team)),
            },
          },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_CREATE_INITIATIVE_FIELD,
      (state, { payload: { field, value } }) =>
        update(state, {
          initiative: {
            [field]: { $set: parseInitiativeField({ field, value }) },
          },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SET_PEOPLE,
      (state, { payload }) =>
        update(state, {
          people: { $set: payload },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SEARCH_PEOPLE_STARTED,
      (state, { payload }) =>
        update(state, {
          people: { $set: payload },
          error: { $set: null },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SEARCH_PEOPLE_SUCCESS,
      (state, { data }) =>
        update(state, {
          people: { $set: { query: state.people.query, results: data.value } },
          error: { $set: null },
        }),
    ],
    [
      FILTER_ACTION_TYPES.SEARCH_PEOPLE_FAILURE,
      (state, { meta }) =>
        update(state, {
          people: { $set: DEFAULT_FILTER_STATE.people },
          error: { $set: meta.statusText },
        }),
    ],
  ]),
  DEFAULT_FILTER_STATE
);
