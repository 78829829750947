import { ALLOWED_FILTER_PARAMS_FOR_API, DESERIALIZE_ALLOWED_KEYS } from '../Constants';
import { AllHtmlEntities } from 'html-entities';

export const filterInitiative = (initiative, method = DESERIALIZE_ALLOWED_KEYS) =>
  Object.entries(initiative).reduce((carry, [key, value]) => {
    if (method.includes(key)) {
      carry[key] = value;
    }
    return carry;
  }, {});

export const filterParams = (selectedFilters) =>
  Object.entries(selectedFilters).reduce((carry, [filter, value]) => {
    if (ALLOWED_FILTER_PARAMS_FOR_API.includes(filter)) {
      if (filter === 'timeframe') {
        carry[filter] = value;
      }

      if (filter === 'team' && value > 0) {
        carry[`${filter}[]`] = [value];
      }
    }
    return carry;
  }, {});

export const deserializeInitiative = (initiative) => ({
  ...initiative,
  size: initiative.size !== 'N/A' ? initiative.size : null,
  priority: parseInt(initiative.priority),
  status: parseInt(initiative.status),
  teams: initiative.teams.map((team) => (team.id ? parseInt(team.id) : parseInt(team))),
  assignees: initiative.assignees || [],
  notes: initiative.notes ? AllHtmlEntities.encode(initiative.notes) : '',
  timeframes: initiative.timeframes.map((quarter) => ({ ...quarter, timeframe: quarter.timeframe.id })),
});
