import React, { useState } from 'react';
import { Heading, Panel } from '@flixbus/honeycomb-react';
import JiraImg from '../../../../assets/imgs/jira.svg';
import { getFilters } from '../../../selectors';
import { connect } from 'react-redux';
import styles from './ViewInitiativePanel.module.scss';
import { DEFAULT_FILTER_TYPES } from '../../../Constants';
import QuarterDetails from './QuarterDetails';
import Milestones from './Milestones';
import Dependencies from './Dependencies';
import InitiativeDetails from './InitiativeDetails';
import InitiativeDescription from './InitiativeDescription';

const ViewInitiativePanel = ({
  timeouts,
  initiative: { teams: initiativeTeams = [DEFAULT_FILTER_TYPES.team.id] },
  initiative,
  showEditInitiativePage,
  closeInitiativeView,
  filters: { priority: priorities = [], status: statuses = [], team: teams },
}) => {
  const [active, setActive] = useState(false);

  timeouts.setTimeout(() => setActive(true), 100);

  const panelHeader = () => (
    <>
      <Heading size={3} extraClasses={styles.panelTitle}>
        {initiative.title}
      </Heading>
      {initiative.jira.length > 0 && initiative.jira.startsWith('https://jira.mfb.io') && (
        <a target='_blank' rel='noopener noreferrer' href={initiative.jira} className={styles.jiraLink}>
          <img src={JiraImg} alt='Go To Jira Page' />
        </a>
      )}
    </>
  );

  return (
    <Panel
      title={panelHeader}
      active={active}
      primaryBtnText='Edit'
      secondaryBtnText='Close'
      onOverlayClick={() => closeInitiativeView(timeouts)}
      onSecondaryBtnClick={() => closeInitiativeView(timeouts)}
      onPrimaryBtnClick={() => showEditInitiativePage(initiative.id)}
      hasOverlay
    >
      <InitiativeDescription
        styles={styles}
        description={initiative.description}
        tags={initiative.tags}
        notes={initiative.notes}
      />

      <InitiativeDetails
        styles={styles}
        assignees={initiative.assignees}
        initiativeTeams={initiativeTeams}
        teams={teams}
        initiativeSize={initiative.size}
      />

      <Dependencies
        title="Depends on:"
        styles={styles}
        dependencies={initiative.fromDependencies}
        initiativeTeamId={initiativeTeams[0]}
      />

      <Dependencies
        title="Initiatives that depend from it:"
        styles={styles}
        dependencies={initiative.toDependencies}
        initiativeTeamId={initiativeTeams[0]}
      />

      <Milestones
        styles={styles}
        milestones={initiative.milestones}
      />

      <QuarterDetails
        styles={styles}
        timeframes={initiative.timeframes}
        priorities={priorities}
        statuses={statuses}
      />
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: getFilters(state),
  };
};

export default connect(mapStateToProps)(ViewInitiativePanel);
