import { DeleteRequest, GetRequest, PostRequest, PutRequest } from '../../../utils';
import { deserializeInitiative, filterInitiative } from '../../actions/Helpers';
import { API_ENDPOINTS } from '../../Constants';
import { serializeInitiative } from '../../reducers/Helpers';

const getEpicDataFromResponse = (epic) => {
  if (epic.success) {
    const serializedInitiative = serializeInitiative(epic.data);

    return {
      ...serializedInitiative,
      teams: epic.data.teams.map((t) => parseInt(t.id, 10)),
      kpis: epic.data.kpis.map((t) => parseInt(t.id, 10)) || [],
      milestones: serializedInitiative.milestones.map((m) => ({ name: m.name, concluded: m.concluded })) || [],
    };
  }

  throw new Error(epic.statusText);
};

export const getEpic = async (id, selectedFilters, authToken) => {
  const url = API_ENDPOINTS.getInitiative(id);
  // TODO: move this inside api call function using react-authentication library
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const epic = await GetRequest(url, {}, options);

  return getEpicDataFromResponse(epic);
};

export const updateEpic = async (payload, authToken) => {
  const url = API_ENDPOINTS.editInitiative(payload.id);
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const data = filterInitiative(deserializeInitiative(payload));
  const epic = await PutRequest(url, data, options);

  return getEpicDataFromResponse(epic);
};

export const createEpic = async (payload, authToken) => {
  const url = API_ENDPOINTS.createInitiative();
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const data = filterInitiative(deserializeInitiative(payload));
  const epic = await PostRequest(url, data, options);

  return getEpicDataFromResponse({ ...epic, data: payload });
};

export const deleteEpic = async (id, authToken) => {
  const url = API_ENDPOINTS.deleteInitiative(id);
  const options = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const epic = await DeleteRequest(url, options);
  if (epic.success) {
    return epic;
  }

  throw new Error(epic.statusText);
};
