import { Button, Grid, GridCol, IconDownload, IconMagnifier, Input, Select, Switch } from '@flixbus/honeycomb-react';
import React, { useEffect, useState } from 'react';

import { getDepartmentByTeamId } from '../../../utils/getDepartmentByTeamId';
import { DEFAULT_FILTER_TYPES } from '../../Constants';

import { TOGGLE_FILTERS } from './Constants';

import styles from './Filters.module.scss';

const Filters = ({
  filters: { timeframe: timeframes = [], department: departments = [], team: teams = [] },
  selectedFilters: { toggles, department, team, timeframe, search },
  showCreateInitiativePage,
  filterActions,
}) => {
  // stores teams options so we can update them and filter by the department
  const [teamsOptions, setTeamsOptions] = useState([]);
  /**
   * set options for teams select box and also sorting them
   * @param arrTeams
   * @returns {*}
   */
  const setTeamOptionsSorted = (arrTeams) => {
    const teamsSorted = arrTeams.sort((a, b) => {
      if (a.sort_order > b.sort_order) {
        return 1;
      }
      if (a.sort_order < b.sort_order) {
        return -1;
      }
      return 0;
    });
    const teamOptionsSorted = teamsSorted.map((team) => {
      const label = team.id === 0 ? team.name : `${team.name} (${team.developers})`;
      return {
        value: team.id,
        displayValue: label,
      };
    });
    setTeamsOptions(teamOptionsSorted);
    return teamOptionsSorted;
  };
  useEffect(() => {
    const departmentSelected = getDepartmentByTeamId(team, teams);
    if (departmentSelected !== null) {
      filterActions.setFilter({
        field: 'department',
        value: departmentSelected.id,
        reload: false,
      });
      // narrows down the list of teams to the department selected team belongs to
      // @todo remove check for Others department once all teams are assigned to a department
      const teamsInDepartment = teams.filter((team) =>
        departmentSelected.id !== 0 ? team.department && team.department.id === departmentSelected.id : !team.department
      );
      setTeamOptionsSorted(teamsInDepartment);
    } else {
      filterActions.setFilter({
        field: 'department',
        value: 0,
        reload: false,
      });
      setTeamOptionsSorted(teams);
    }
  }, [team, teams]);
  const onFilterChange = (e) => {
    const field = e.target.id;
    const value = e.target.value;

    if (TOGGLE_FILTERS.includes(field)) {
      return filterActions.setFilter({
        field: 'toggles',
        value: { ...toggles, [field]: !toggles[field] },
      });
    }

    if (field === 'search') {
      return filterActions.setFilter({ field, value, reload: false });
    }

    if (field === 'team') {
      const departmentOfTeamSelected = getDepartmentByTeamId(value, teams);
      filterActions.setFilter({
        field: 'department',
        value: departmentOfTeamSelected ? departmentOfTeamSelected.id : 0,
        reload: false,
      });
    }

    field && filterActions.setFilter({ field, value });
  };
  // filters out teams by the department given
  const onDepartmentChange = (e) => {
    const departmentId = parseInt(e.target.value);
    const teamsInDepartment = teams.filter((team) =>
      departmentId !== 0 ? team.department && team.department.id === departmentId : !team.department
    );
    const teamsInDepartmentOptions = setTeamOptionsSorted(teamsInDepartment);
    // @todo we might not need storing department filter in redux as it only filters teams in the filter input,
    //  not initiatives
    filterActions.setFilter({
      field: 'department',
      value: departmentId,
      reload: false,
    });
    // setting the view to the first team in the list
    filterActions.setFilter({
      field: 'team',
      value: teamsInDepartmentOptions[0].value,
    });
  };

  const onPrint = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.print();
  };
  // preparing the list of options for Select component
  const timeframeOptions = timeframes.map((item) => ({
    label: item.name,
    value: item.slug,
    displayValue: item.name,
  }));
  const departmentOptionsTmp = departments.map((department) => ({
    value: department.id,
    displayValue: department.name,
  }));
  // sorts departments alphabetically
  const departmentOptions = departmentOptionsTmp.sort((a, b) => {
    if (a.displayValue > b.displayValue) {
      return 1;
    }
    if (a.displayValue < b.displayValue) {
      return -1;
    }
    return 0;
  });
  // injecting "Others" department to deal with teams without departments
  // @todo remove this once all teams assigned to a department
  departmentOptions.unshift({
    value: DEFAULT_FILTER_TYPES.department.id,
    displayValue: DEFAULT_FILTER_TYPES.department.name,
  });

  return (
    <div className={styles.filterContainer}>
      <Grid justify="center" align="center">
        <GridCol size={12} md={4}>
          <Grid align="center">
            <GridCol size={12} sm={6} md={7}>
              <div className={styles.buttons}>
                <Button appearance="primary" onClick={showCreateInitiativePage}>
                  Add Epic
                </Button>
                <Button InlineIcon={IconDownload} display="square" onClick={onPrint} aria-label="Download" />
              </div>
            </GridCol>
            <GridCol size={12} sm={6} md={5}>
              <Input
                id="search"
                aria-label="Search"
                InlineIcon={IconMagnifier}
                onChange={onFilterChange}
                value={search}
              />
            </GridCol>
          </Grid>
        </GridCol>
        <GridCol size={12} md={5}>
          <Grid align="center">
            <GridCol size={12} sm={6}>
              <Select
                id="department"
                aria-label="Department"
                options={departmentOptions}
                onChange={onDepartmentChange}
                value={department}
              />
            </GridCol>
            <GridCol size={12} sm={6}>
              <Select id="team" aria-label="Team" options={teamsOptions} onChange={onFilterChange} value={team} />
            </GridCol>
          </Grid>
        </GridCol>
        <GridCol size={12} md={3}>
          <Grid align="center">
            <GridCol size={6}>
              <Select
                id="timeframe"
                aria-label="Time Frame"
                options={timeframeOptions}
                onChange={onFilterChange}
                value={timeframe}
              />
            </GridCol>
            <GridCol size={6}>
              <Switch
                id="description"
                label="Details"
                checked={toggles.description}
                onChange={onFilterChange}
                extraClasses={styles.switchItem}
              />
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </div>
  );
};

export default Filters;
