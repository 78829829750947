import { Heading } from '@flixbus/honeycomb-react';
import React from 'react';

import NotFoundImage from '../../assets/imgs/404.jpg';

import styles from './NotFound.module.scss';

const NotFound: React.FC = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFoundContent}>
        <Heading size={1}>404 - page not available</Heading>
        <img src={NotFoundImage} className={styles.notFoundImage} alt="Vacations are great" />
        <div className={styles.borderLine} />
        <div className={styles.notFoundText}>
          <p>Dear visitor,</p>
          <p>This page is no longer available. Reasons for this may be:</p>
          <ul>
            <li>You used the language switcher and your desired page doesn’t exist in your chosen language</li>
            <li>We have archived or renamed this page so it has therefore expired</li>
            <li>You have typed in an incorrect URL</li>
          </ul>
          <p>
            Perhaps you can find the content you are looking for via our home page where you can navigate to the
            information&nbsp;that you need!
            <br />
            Search for your coach trip now...
          </p>
          <p>
            <a href="/" target="_self">
              Flix away to the home page!
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
