import { Reducer } from 'redux';

import { APP_GLOBALS_DEPENDENCY_FILTERS_SET, AppGlobalsStateActions } from '../actions/appGlobals.actions';
import { AppGlobalState } from '../types';

const initialState: AppGlobalState = {
  dependencyFilters: {
    team: 17,
  },
};

const appGlobalStateReducer: Reducer<AppGlobalState, AppGlobalsStateActions> = (state = initialState, action) => {
  switch (action.type) {
    case APP_GLOBALS_DEPENDENCY_FILTERS_SET: {
      return {
        ...state,
        dependencyFilters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default appGlobalStateReducer;
