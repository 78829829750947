import { merge } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { API_ACTION_TYPES } from '../../config';
import { DeleteRequest, GetRequest, PostRequest, PutRequest } from '../../utils';
import { getAuth } from '../selectors';
import { BASE_URL } from '../Constants';

const prepareAuthOptions = (accessToken, options) =>
  merge(options || {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const getResponse = (status, success, action) =>
  Boolean(action.responses[status])
    ? action.responses[status]
    : success
    ? action.responses.success
    : action.responses.error;

function* apiRequest(action) {
  try {
    yield action.requestType && put({ type: action.requestType, payload: action.payload });

    let accountInfo = yield select(getAuth);

    let options = prepareAuthOptions(
      (accountInfo && accountInfo[action.endpoint.startsWith(BASE_URL) ? 'jwtIdToken' : 'jwtAccessToken']) || '',
      action.options
    );

    const response =
      action.method === 'GET'
        ? yield call(GetRequest, action.endpoint, action.params, options)
        : action.method === 'POST'
        ? yield call(PostRequest, action.endpoint, action.body, options)
        : action.method === 'PUT'
        ? yield call(PutRequest, action.endpoint, action.body, options)
        : action.method === 'DELETE'
        ? yield call(DeleteRequest, action.endpoint, options)
        : {};

    const responsePayload = getResponse(response.status, response.success || response.status === 204, action);

    yield put({
      ...responsePayload,
      data: response.data,
      meta: {
        status: response.status,
        statusText: response.statusText,
        data: responsePayload.meta,
      },
    });
  } catch (e) {
    console.log(e);

    yield put({
      ...action.responses.error,
      data: null,
      meta: {
        status: e.status,
        statusText: e.message,
      },
    });
  }
}

export default function* apiSaga() {
  yield takeEvery(API_ACTION_TYPES.TRIGGER_API_REQUEST, apiRequest);
}
