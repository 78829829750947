import { useQuery } from 'react-query';

import { useAxios } from '../../utils/transport/useAxios';
import { API_ENDPOINTS } from '../Constants';
import { Dependency, DependencyFilters } from '../types';

export function useDependencyListQuery(filters: DependencyFilters) {
  const axios = useAxios();
  return useQuery<Dependency[], Error>(['dependencyList', filters], async () => {
    const filterParams = Object.entries(filters).reduce((acc, [k, v]) => {
      if (!!v) {
        acc[k] = v.toString();
      }
      return acc;
    }, {} as Record<string, string>);

    const params = new URLSearchParams(filterParams).toString();
    const { data } = await axios.get<Dependency[]>(API_ENDPOINTS.getDependencies(params));
    return data;
  });
}
