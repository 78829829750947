import { merge } from 'lodash';
import { API_ACTION_TYPES } from '../config';

const ApiDefaultOptions = () => ({
  headers: {
    'content-type': 'application/json',
    'accept': 'application/json',
  },
});

const ApiRequest = async (url, options) => {
  const mergedOptions = merge(ApiDefaultOptions(), options);
  const response = await fetch(url, mergedOptions);
  if (response.status === 403) {
    localStorage.clear();
    return;
  }

  let data = '';

  if (response.ok) {
    const contentType = response.headers.get('content-type') || '';

    // @TODO: Better Content Type Handling
    if (contentType.startsWith('application/json')) data = await response.clone().json();
    else if (contentType.startsWith('text/plain')) data = await response.clone().text();
    else if (contentType.startsWith('image/')) data = await response.clone().arrayBuffer();
    else data = await response.clone().text();
  }

  const apiResponse = {
    success: response.ok || response.status === 204,
    status: response.status,
    statusText: response.statusText,
    data,
  };

  return new Promise((resolve) => resolve(apiResponse));
};

export const GetRequest = async (url, params, options = {}) => {
  if (params) {
    url = new URL(url);
    url.search = new URLSearchParams(params).toString();
  }

  return await ApiRequest(url.toString(), { method: API_ACTION_TYPES.GET_METHOD, ...options });
};

export const PostRequest = async (url, data, options = {}) =>
  ApiRequest(url, {
    method: API_ACTION_TYPES.POST_METHOD,
    body: JSON.stringify(data),
    ...options,
  });

export const PutRequest = async (url, data, options = {}) =>
  ApiRequest(url, {
    method: API_ACTION_TYPES.PUT_METHOD,
    body: JSON.stringify(data),
    ...options,
  });

export const DeleteRequest = async (url, options = {}) =>
  ApiRequest(url, {
    method: API_ACTION_TYPES.DELETE_METHOD,
    ...options,
  });
