import { API_ACTION_TYPES, APP_ACTION_TYPES } from '../../config';
import { createActions } from 'redux-actions';
import { GRAPH_ENDPOINTS } from '../Constants';

export default createActions({
  [APP_ACTION_TYPES.PREFIX]: {
    [APP_ACTION_TYPES.TRIGGER_SET_DEVICE_DATA]: () => ({
      type: APP_ACTION_TYPES.SET_DEVICE_DATA,
    }),
    [APP_ACTION_TYPES.TRIGGER_SET_PAGE]: (currentPage) => ({
      type: APP_ACTION_TYPES.SET_PAGE,
      currentPage,
    }),
    [APP_ACTION_TYPES.TRIGGER_SET_AUTH_DATA]: (payload) => ({
      type: APP_ACTION_TYPES.SET_AUTH_DATA,
      payload,
    }),
    [APP_ACTION_TYPES.TRIGGER_SHOW_DELETE_POPUP]: () => ({
      type: APP_ACTION_TYPES.SHOW_DELETE_POPUP,
    }),
    [APP_ACTION_TYPES.TRIGGER_HIDE_DELETE_POPUP]: () => ({
      type: APP_ACTION_TYPES.HIDE_DELETE_POPUP,
    }),
    [APP_ACTION_TYPES.TRIGGER_SET_PROFILE_DATA]: (payload) => ({
      type: API_ACTION_TYPES.TRIGGER_API_REQUEST,
      endpoint: GRAPH_ENDPOINTS.getProfilePicture(),
      method: API_ACTION_TYPES.GET_METHOD,
      options: { headers: { Authorization: `Bearer ${payload.jwtAccessToken}` } },
      responses: {
        success: { type: APP_ACTION_TYPES.SET_PROFILE_PICTURE },
        error: { type: APP_ACTION_TYPES.REQUEST_ERROR },
      },
    }),
  },
});
