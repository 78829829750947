import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { Grid } from '@flixbus/honeycomb-react';

import { LoadingSpinner } from '../../../shared';
import { areInitiativesLoading, getPriorities } from '../../selectors';
import RoadmapDragLayer from './roadmap-drag-layer';
import RoadmapColumn from './roadmap-column';

const Roadmap = ({ editInitiative, showInitiativeViewPanel, createdBy }) => {
  const isLoading = useSelector(areInitiativesLoading);
  const priorities = useSelector(getPriorities);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid align="top">
      <DndProvider backend={HTML5Backend}>
        {priorities.map((priority) => {
          const id = parseInt(priority.id, 10);

          return (
            <RoadmapColumn
              key={id}
              showInitiativeViewPanel={showInitiativeViewPanel}
              editInitiative={editInitiative}
              priority={id}
              createdBy={createdBy}
            />
          );
        })}
        <RoadmapDragLayer />
      </DndProvider>
    </Grid>
  );
};

export default React.memo(Roadmap);
