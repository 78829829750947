import { Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';
import React from 'react';

const DepartmentTeamPickerSkeletonComp: React.FC = () => {
  return (
    <Grid align={'center'}>
      <GridCol size={6}>
        <Skeleton width="lg" height="lg" />
      </GridCol>
      <GridCol size={6}>
        <Skeleton width="lg" height="lg" />
      </GridCol>
    </Grid>
  );
};

export const DepartmentTeamPickerSkeleton = React.memo(DepartmentTeamPickerSkeletonComp);
