import React, { useState, useEffect } from 'react';
import { Button, Box, Icon, IconEdit, IconClose, Heading } from '@flixbus/honeycomb-react';
import { BACKEND_PAGES } from '../../Constants';
import { LoadingSpinner } from '../../../shared';

import KpiRow from './KpiRow';

import BullseyeIcon from '../../../assets/imgs/bullseye-arrow.svg';

import styles from './Kpis.module.scss';

const Kpis = ({ team }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [boxVisible, setBoxVisible] = useState(false);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    setData(team.kpis);
  }, [team, isMounted]);

  return (
    <div className={styles.wrapper}>
      <Button
        appearance='secondary'
        onClick={() => setBoxVisible(true)}
        extraClasses={[styles.button, boxVisible ? styles.visible : ''].join(' ')}
      >
        <img className={styles.buttonImg} src={BullseyeIcon} alt='' />
      </Button>
      <Box highlighted extraClasses={[styles.box, boxVisible ? styles.visible : ''].join(' ')}>
        <header className={styles.header}>
          <Heading extraClasses={styles.heading} size={4}>
            KPIs
          </Heading>
          <div>
            <a className={styles.headerIcon} href={BACKEND_PAGES.editTeam(team.id)}>
              <Icon InlineIcon={IconEdit} size='sm' />
            </a>
            <Icon
              InlineIcon={IconClose}
              size='sm'
              onClick={() => setBoxVisible(false)}
              extraClasses={styles.headerIcon}
            />
          </div>
        </header>
        {data === undefined ? <LoadingSpinner /> : data.map((x) => <KpiRow key={x.id} kpi={x} />)}
      </Box>
    </div>
  );
};

export default Kpis;
