import React from 'react';
import { DataTable, Heading, Link, Icon, IconEye, IconLink, IconCheckmarkStrong } from '@flixbus/honeycomb-react';

const STATUS_ICONS = {
  'IDENTIFIED': <Icon InlineIcon={IconEye} title="Identified" />,
  'LINKED': <Icon InlineIcon={IconLink} title="Linked" />,
  'RESOLVED': <Icon InlineIcon={IconCheckmarkStrong} title="Resolved" />
}

const Dependencies = ({title, styles, dependencies, initiativeTeamId}) => {
  const depRows = dependencies.map((dep) => {
    let epic = dep['from_initiative'] && <Link href={dep['from_initiative'].link}>{dep['from_initiative'].title}</Link>;
    let team = dep['from_team'].name;

    if (initiativeTeamId === dep['from_team'].id) {
      epic = dep['to_initiative'] && <Link href={dep['to_initiative'].link}>{dep['to_initiative'].title}</Link>;
      team = dep['to_team'].name;
    }

    return {cells: [team, epic, STATUS_ICONS[dep.status]]};
  });

  return depRows && depRows.length > 0 && (
    <div className={styles.panelTable}>
      <Heading size={3}>{title}</Heading>
      <DataTable small rows={depRows} headers={{cells: ['Team Name', 'Epic', 'Status']}} />
    </div>
  );
}

export default Dependencies;
