import { Tooltip, Tag } from '@flixbus/honeycomb-react';
import cn from 'classnames';
import React, { useState } from 'react';

import { DEFAULT_HINT, STATUS_HINT, MILESTONE_STATUS } from '../../Constants';

import styles from './RoadmapCellItem.module.scss';
import { DATE_FORMAT } from '../../../../Constants';

const RoadmapCellItem = ({ initiative, showInitiativeViewPanel, selectedFilters }) => {
  const {
    id,
    title,
    description,
    status,
    status_message,
    size,
    milestones = [],
    tags = [],
    kpis = [],
    updatedAt,
    teams,
  } = initiative;

  const [showHint, shouldShowHint] = useState(false);

  const handleMouseHover = () => shouldShowHint(!showHint);

  const listTags = tags.slice(0, 4).sort((a, b) => b.length - a.length);

  const {
    toggles: { description: showDescription },
    team,
  } = selectedFilters;

  const hint = STATUS_HINT[status] || DEFAULT_HINT;

  const sortedMilestones = milestones.sort((a, b) => (a.concluded === b.concluded ? 0 : a.concluded ? -1 : 1));

  const progressPills = Array.from(
    { length: milestones.length },
    (_, i) => 0 | ((i < 100 % milestones.length ? 100 / milestones.length + 1 : 100 / milestones.length) * (i + 1))
  );

  const hintContent = (
    <div>
      <b>{hint.text}</b>
      <p>{status_message}</p>
      <small>Last Updated: {new Date(updatedAt).toLocaleDateString(undefined, DATE_FORMAT)}</small>
    </div>
  );

  return (
    <>
      <div onClick={() => showInitiativeViewPanel(id)}>
        <div className={styles.content}>
          {team === 0 && <span className={styles.teamName}>Team {teams[0].name}</span>}
          {!showDescription && progressPills.length > 0 && (
            <div className={styles.progress}>
              {progressPills.map((x, i) => (
                <span
                  key={i}
                  style={{ flex: `${100 / milestones.length} 1` }}
                  className={cn(styles.pill, { [styles.activePill]: sortedMilestones[i].concluded })}
                />
              ))}
            </div>
          )}
          <span className={styles.title}>{title}</span>
          <span className={styles.hintContainer} onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover}>
            <Tooltip
              active={showHint}
              position='bottom'
              content={hintContent}
              extraClasses={cn(styles.hint, styles[hint.appearance])}
            >
              <img alt='' className={styles.hintIcon} src={hint.img} />
            </Tooltip>
          </span>
          {size && size !== 'N/A' && <span className={styles.shirt}>{size}</span>}
        </div>
        {showDescription && (
          <>
            {description.length > 0 && (
              <div className={styles.description}>
                <p dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
              </div>
            )}
            {milestones.length > 0 && (
              <ul className={styles.milestones}>
                {sortedMilestones.map((a, index) => (
                  <li key={index}>
                    <img src={MILESTONE_STATUS[a.concluded]} alt={a.concluded ? 'concluded' : 'not-concluded'} />
                    {` ${a.name}`}
                  </li>
                ))}
              </ul>
            )}
            {tags.length + kpis.length > 0 && (
              <div className={styles.tags}>
                {kpis.map((x) => (
                  <Tag extraClasses={styles.tag} key={x.id}>
                    {x.emoji}
                  </Tag>
                ))}
                {listTags.map((a, index) => (
                  <Tag extraClasses={styles.tag} key={index}>
                    <span>{a.toLowerCase()}</span>
                  </Tag>
                ))}
                {tags.length > 4 && <Tag extraClasses={styles.tag}>+{tags.length - 4}</Tag>}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(RoadmapCellItem);
