import React, { useEffect } from 'react';
import { AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { APP_ACTIONS, FILTER_ACTIONS } from './actions';
import { Header } from './components';
import { APP_PAGES } from './Constants';
import { DependencyDashboard, Epic, Home, NotFound } from './pages';

import styles from './App.module.scss';

export const App: React.FC<IAzureADFunctionProps> = (props) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    if (props.authenticationState === AuthenticationState.Authenticated) {
      dispatch(APP_ACTIONS.triggerSetAuthData(props.accountInfo));
      dispatch(FILTER_ACTIONS.triggerFetchFilters());
    }
  }, [dispatch, props]);

  const { authenticationState, login, logout } = props;

  return (
    <div className={styles.container}>
      <Header authenticationState={authenticationState} login={login} logout={logout} />
      <Switch>
        <Route
          exact
          path={[...APP_PAGES.root.regex, ...APP_PAGES.home.regex, ...APP_PAGES.view.regex]}
          render={() => <Home match={match} />}
        />
        <Route exact path={APP_PAGES.form.regex} component={Epic} />
        <Route exact path={APP_PAGES.dependencyDashboard.regex} component={DependencyDashboard} />
        <Route path={APP_PAGES.notFound.regex} component={NotFound} />
      </Switch>
    </div>
  );
};

export default React.memo(App);
