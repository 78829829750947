import { Box, Grid, GridCol, Heading, Icon, IconCheckmarkStrong, IconEye, IconLink } from '@flixbus/honeycomb-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { DependencyStatus } from '../../../Constants';
import { useDependencyReportQuery } from '../../../data/useDependencyReportQuery';
import { getDependencyFilters } from '../../../selectors/appGlobals.selectors';

import { DependencyReportSkeleton } from './DependencyReportSkeleton';

import styles from './DependencyReport.module.scss';

const DependencyReportComp: React.FC = () => {
  const dependencyFilters = useSelector(getDependencyFilters);
  const { isLoading, error, data } = useDependencyReportQuery(dependencyFilters);
  if (isLoading) {
    return <DependencyReportSkeleton />;
  } else if (!!error) {
    return <span>{error.message}</span>;
  }
  // console.log(data)

  return (
    <Grid align={'center'}>
      {Object.entries(data?.status || []).map(([key, status]) => {
        let icon = IconEye;
        if (key === DependencyStatus.LINKED) {
          icon = IconLink;
        } else if (key === DependencyStatus.RESOLVED) {
          icon = IconCheckmarkStrong;
        }

        return (
          <GridCol size={12} md={4} key={key}>
            <Box extraClasses={styles.boxContainer} key={key}>
              <div className={styles.heading}>
                <Heading size={3} flushSpace>
                  {key.toLowerCase()}
                </Heading>
                <Heading size={3} flushSpace>
                  {status}
                </Heading>
              </div>
              <div className={styles.iconContainer}>
                <Icon size={'lg'} InlineIcon={icon} />
              </div>
            </Box>
          </GridCol>
        );
      })}
    </Grid>
  );
};

export const DependencyReport = React.memo(DependencyReportComp);
