import { REQUIRED_KEYS, SHIRT_SIZES } from './Constants';

export function createParameterSelector(selector) {
  return (_, params) => selector(params);
}

export const parseJSON = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === 'object') return o;
  } catch {}

  return false;
};

export const getAssigneeName = (assignee) => {
  const splitName = assignee.split('.');

  splitName[1] = (splitName[1] || '@').split('@')[0];

  return splitName;
};

export const searchInitiatives = (initiatives, search) =>
  initiatives.filter(
    (initiative) =>
      initiative.title.toString().toLowerCase().includes(search) ||
      initiative.assignees.join().toLowerCase().includes(search) ||
      initiative.tags.join().toLowerCase().includes(search)
  );

export const cleanSearchString = (search) => search.toString().toLowerCase();

export const getEpicPayload = (epic, team, filters, selectedFilters) => {
  let timeframes = [...epic.timeframes];

  if (timeframes.length === 0) {
    const timeframe = filters.timeframe.find((t) => t.slug === selectedFilters.timeframe) || filters.timeframe[0];
    timeframes = [
      {
        priority: 1,
        status: 1,
        status_message: '',
        timeframe,
      },
    ];
  }

  return {
    ...epic,
    size: epic.size || SHIRT_SIZES.NO_SIZE,
    teams: [team.id],
    assignees: [],
    kpis: epic.kpis || [],
    timeframes,
  };
};

export const getEpicValidationState = (epic) => {
  let errors = [];

  if (!epic) {
    errors.push('Required fields empty!');
  } else {
    REQUIRED_KEYS.forEach((k) => {
      !epic[k] && errors.push(`Required field ${k} missing!`);
    });

    epic.teams && epic.teams.length > 0 && epic.teams[0] === 0 && errors.push(`Teams can't be 'All Teams`);
    epic.description && !/\S/.test(epic.description) && errors.push(`Description can't be empty string!`);
    epic.notes && !/\S/.test(epic.notes) && errors.push(`Notes can't be empty string!`);
    epic.milestones &&
      epic.milestones.length > 0 &&
      epic.milestones.some((m) => !m.name || !/\S/.test(m.name)) &&
      errors.push(`Milestone can't be empty!`);
    epic.tags &&
      epic.tags.length > 0 &&
      epic.tags.some((t) => !t || !/\S/.test(t)) &&
      errors.push(`Tag can't be empty!`);
    epic.jira.length && !/\S/.test(epic.jira) && errors.push(`Jira link can't be empty!`);
  }

  return {
    valid: errors.length === 0,
    errors,
  };
};
