import { PageContainer, Notification, NotificationContainer, Grid, GridCol, Heading } from '@flixbus/honeycomb-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingSpinner } from '../../../shared';
import { FILTER_ACTIONS, INITIATIVE_ACTIONS } from '../../actions';
import { APP_PAGES, DEFAULT_FILTER_STATE } from '../../Constants';
import { getEpicPayload } from '../../Helpers';
import {
  areFiltersLoading,
  getAuth,
  getFilters,
  getPeople,
  getSelectedFiltersForCurrentView,
  getTeamFromId,
} from '../../selectors';
import { EpicFormContainer } from './components/EpicFormContainer';
import { createEpic, deleteEpic, getEpic, updateEpic } from './effects';

import styles from './Epic.module.scss';

const EpicComp = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { teamId, epicId, mode } = match.params;
  const isFiltersLoading = useSelector(areFiltersLoading);
  const filters = useSelector(getFilters);
  const selectedFilters = useSelector(getSelectedFiltersForCurrentView);
  const team = useSelector((state) => getTeamFromId(state, { teamId: parseInt(teamId, 10) }));
  const auth = useSelector(getAuth);
  const people = useSelector(getPeople);

  const isEditMode = mode === 'edit';
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [epic, setEpic] = useState(null);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    (async () => {
      if (auth && auth.jwtIdToken && !isFiltersLoading) {
        setIsLoading(true);
        if (isEditMode) {
          const epicResponse = await getEpic(epicId, selectedFilters, auth.jwtIdToken);
          if (!epicResponse) {
            setError('Cannot find epic details!!');
          } else {
            setEpic(epicResponse);
          }
        }
        setIsLoading(false);
      }
    })();
  }, [isFiltersLoading, auth, isEditMode, epicId, selectedFilters]);

  // TODO: change main route to to render till it has auth and filters loaded so that  dependency is []
  useEffect(() => {
    if (team) {
      setEpic(getEpicPayload(DEFAULT_FILTER_STATE.initiative, team, filters, selectedFilters));
    }
  }, [team, filters, selectedFilters]);

  const showNotification = (notification) => {
    setNotifications([...notifications, notification]);
    setTimeout(() => setNotifications([]), 5000);
  };

  const searchPeople = (payload) => dispatch(FILTER_ACTIONS.triggerSearchPeople(payload));

  const resetPeople = () => dispatch(FILTER_ACTIONS.triggerSetPeople(DEFAULT_FILTER_STATE.people));

  const goBack = (timeframe = selectedFilters.timeframe) => {
    // TODO: handle this in home??
    dispatch(INITIATIVE_ACTIONS.triggerFetchInitiatives(selectedFilters));
    const homePath = APP_PAGES.home.path(timeframe, teamId);
    history.push(homePath);
  };

  const clearError = () => setError(null);

  const deleteInitiative = async () => {
    setIsDeleting(true);
    setIsSaving(false);
    try {
      await deleteEpic(epicId, auth.jwtIdToken);
      setEpic(DEFAULT_FILTER_STATE.initiative);
      goBack(epic.timeframes[0].timeframe.slug);
    } catch (e) {
      setError(`${e.message} - Deleting epic failed!`);
    }
    setIsDeleting(false);
  };

  const createOrEditInitiative = async (epic, navigateToHome) => {
    setIsSaving(true);
    setIsDeleting(false);
    try {
      if (isEditMode) {
        const epicResponse = await updateEpic(epic, auth.jwtIdToken);
        if (navigateToHome) {
          goBack(epicResponse.timeframes[0].timeframe.slug);
        } else {
          showNotification({ type: 'success', message: 'Epic updated!' });
          setEpic(epicResponse);
        }
      } else {
        if (epic.teams[0] === 0) {
          setIsSaving(false);
          return alert('You need to choose a team. You cannot select "All Teams" as team');
        }
        const epicResponse = await createEpic({ ...epic, createdBy: auth.account.userName }, auth.jwtIdToken);
        if (navigateToHome) {
          goBack(epicResponse.timeframes[0].timeframe.slug);
        } else {
          showNotification({ type: 'success', message: 'Epic created!' });
          setEpic(getEpicPayload(DEFAULT_FILTER_STATE.initiative, team, filters, selectedFilters));
        }
      }
    } catch (e) {
      setError(`${e.message} - Saving epic failed! Could be form errors!`);
    }
    setIsSaving(false);
  };

  if (isFiltersLoading || isLoading) {
    return <LoadingSpinner />;
  } else if (epic) {
    const formProps = {
      actions: {
        goBack,
        onSave: createOrEditInitiative,
        onDelete: deleteInitiative,
        clearError,
        searchPeople,
      },
      actionState: {
        isEditMode,
        isSaving,
        isDeleting,
        error,
      },
      epic,
      filters,
      people,
      selectedFilters,
    };

    return (
      <PageContainer extraClasses={styles.container} onClick={resetPeople}>
        <Grid>
          <GridCol size={12}>
            <Heading size={1}> {isEditMode ? 'Edit Epic' : 'Add Epic'}</Heading>
          </GridCol>
          <GridCol size={12}>
            <EpicFormContainer {...formProps} />
          </GridCol>
        </Grid>
        <NotificationContainer position='bottom-right'>
          <>
            {notifications.map((n, index) => (
              <Notification toast appearance={n.type} key={index}>
                {n.message}
              </Notification>
            ))}
          </>
        </NotificationContainer>
      </PageContainer>
    );
  }

  return null;
};

export const Epic = React.memo(EpicComp);
