import { Box, Icon, IconDeleteSolid, Input } from '@flixbus/honeycomb-react';
import React from 'react';

import { DELVE } from '../../../../Constants';
import { getAssigneeName } from '../../../../Helpers';

import styles from './Assignees.module.scss';

const AssigneesList = ({ assigneeList = [], setAssignees, people, searchPeople }) => {
  const { query = '', results = [] } = people;

  const onSearch = (e) => searchPeople({ query: e.target.value.trimStart(), results: [] });

  const onAssigneeDeleted = (assignee) => setAssignees(assigneeList.filter((item) => item !== assignee));

  const onAssigneeAdded = (assignee) => {
    if (!assigneeList.includes(assignee)) {
      setAssignees([...assigneeList, assignee]);
    }
  };

  return (
    <Box extraClasses={styles.box}>
      <Input id='people-search' label='Names' placeholder='Search assignee' onChange={onSearch} value={query} />

      {results.length > 0 && (
        <Box highlighted={true} extraClasses={styles.peopleSearchBox}>
          <div className={styles.peopleSearchList}>
            {results.map((people) => {
              const addPeople = () => onAssigneeAdded(people['mail']);

              return (
                <span key={people.id} onClick={addPeople} className={styles.peopleSearchItem}>
                  {people.displayName}
                </span>
              );
            })}
          </div>
        </Box>
      )}

      {assigneeList && assigneeList.length > 0 && (
        <div className={styles.container}>
          {assigneeList.map((assignee) => {
            const assigneeDeleted = () => onAssigneeDeleted(assignee);
            const splitName = getAssigneeName(assignee);

            return (
              <div className={styles.row} key={assignee}>
                <Icon InlineIcon={IconDeleteSolid} extraClasses={styles.delIcon} onClick={assigneeDeleted} />
                <a className={styles.peopleEmail} href={DELVE(assignee)} target='_blank' rel='noopener noreferrer'>
                  {`${splitName[0]} ${splitName[1]}`}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export const Assignees = React.memo(AssigneesList);
