import { useQuery } from 'react-query';

import { useAxios } from '../../utils/transport/useAxios';
import { API_ENDPOINTS } from '../Constants';
import { AppFilters } from '../types';

export function useFiltersListQuery() {
  const axios = useAxios();
  return useQuery<AppFilters, Error>(['filters'], async () => {
    const { data } = await axios.get<AppFilters>(API_ENDPOINTS.getFilters());
    return data;
  });
}
