import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { INITIATIVE_ACTION_TYPES } from '../../config';
import { DEFAULT_INITIATIVE_STATE } from '../Constants';

export default handleActions(
  new Map([
    [
      INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_STARTED,
      (state) =>
        update(state, {
          loading: { $set: true },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_SUCCESS,
      (state, { data }) => {
        return update(state, {
          roadmap: { $set: data },
          loading: { $set: false },
          error: { $set: null },
        });
      },
    ],
    [
      INITIATIVE_ACTION_TYPES.FETCH_INITIATIVES_FAILURE,
      (state, { meta }) =>
        update(state, {
          roadmap: { $set: DEFAULT_INITIATIVE_STATE.roadmap },
          loading: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_STARTED,
      (state) =>
        update(state, {
          loading: { $set: true },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_SUCCESS,
      (state) =>
        update(state, {
          loading: { $set: false },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.CREATE_INITIATIVE_FAILURE,
      (state, { meta }) =>
        update(state, {
          loading: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_STARTED,
      (state) =>
        update(state, {
          loading: { $set: true },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_SUCCESS,
      (state) =>
        update(state, {
          loading: { $set: false },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.EDIT_INITIATIVE_FAILURE,
      (state, { meta }) =>
        update(state, {
          loading: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_STARTED,
      (state) =>
        update(state, {
          loading: { $set: true },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_SUCCESS,
      (state) =>
        update(state, {
          loading: { $set: false },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DELETE_INITIATIVE_FAILURE,
      (state, { meta }) =>
        update(state, {
          loading: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_STARTED,
      (state) =>
        update(state, {
          loading: { $set: false },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_SUCCESS,
      (state) =>
        update(state, {
          loading: { $set: false },
          error: { $set: null },
        }),
    ],
    [
      INITIATIVE_ACTION_TYPES.DRAG_INITIATIVE_FAILURE,
      (state, { meta }) =>
        update(state, {
          loading: { $set: false },
          error: { $set: meta.statusText },
        }),
    ],
  ]),
  DEFAULT_INITIATIVE_STATE
);
