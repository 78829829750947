import { useQuery } from 'react-query';

import { useAxios } from '../../utils/transport/useAxios';
import { API_ENDPOINTS, DependencyStatus } from '../Constants';
import { DependencyFilters } from '../types';

type DependencyReportResponse = {
  status: {
    [k in DependencyStatus]: number;
  };
};
export function useDependencyReportQuery(filters: DependencyFilters) {
  const axios = useAxios();
  return useQuery<DependencyReportResponse, Error>(['dependencyReport', filters], async () => {
    const filterParams = Object.entries(filters).reduce((acc, [k, v]) => {
      if (!!v) {
        acc[k] = v.toString();
      }
      return acc;
    }, {} as Record<string, string>);

    const params = new URLSearchParams(filterParams).toString();
    const { data } = await axios.get<DependencyReportResponse>(API_ENDPOINTS.getDependencyReport(params));
    return data;
  });
}
