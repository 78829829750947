import IconDelayed from '../../../assets/imgs/delayed.svg';
import IconBulb from '../../../assets/imgs/idea.svg';
import IconFlag from '../../../assets/imgs/flag.svg';
import IconDeprioritized from '../../../assets/imgs/deprioritized.svg';
import IconPlus from '../../../assets/imgs/plus.svg';
import IconCheckmark from '../../../assets/imgs/checkmark.svg';
import IconCheckmarkUnchecked from '../../../assets/imgs/checkmark-unchecked.svg';
import IconBlocked from '../../../assets/imgs/blocked.svg';

export const STATUS_HINT = {
  '1': {
    text: 'Epic',
    img: IconBulb,
    appearance: 'default',
  },
  '2': {
    text: 'On Track',
    img: IconFlag,
    appearance: 'success',
  },
  '3': {
    text: 'Done',
    img: IconCheckmark,
    appearance: 'success',
  },
  '4': {
    text: 'Injected',
    img: IconPlus,
    appearance: 'warning',
  },
  '5': {
    text: 'De-prioritized',
    img: IconDeprioritized,
    appearance: 'error',
  },
  '6': {
    text: 'Delayed',
    img: IconDelayed,
    appearance: 'warning',
  },
  '7': {
    text: 'Blocked',
    img: IconBlocked,
    appearance: 'error',
  },
};

export const MILESTONE_STATUS = {
  false: IconCheckmarkUnchecked,
  true: IconCheckmark,
};

export const DEFAULT_HINT = STATUS_HINT['1'];

export const ITEM_TYPES = {
  INITIATIVE: 'initiative',
};
