import React from 'react';
import { Button, Icon, IconDeleteSolid, IconOfferSolid, Input } from '@flixbus/honeycomb-react';
import styles from './Tags.module.scss';

const TagsList = ({ tags = [], setTags }) => {
  const onAddTag = () => {
    setTags([...tags, '']);
  };

  const onEditTag = (tag, idx) => {
    const payload = tags.map((ms, index) => {
      if (idx === index) {
        return tag;
      }

      return ms;
    });
    setTags(payload);
  };

  const onTagDeleted = (index) => {
    const payload = tags.filter((_, idx) => idx !== index);
    setTags(payload);
  };

  return (
    <div className={styles.box}>
      <div className={styles.container}>
        {tags.map((tag, index) => {
          const tagEdited = (e) => onEditTag(e.target.value, index);
          const tagDeleted = () => onTagDeleted(index);

          return (
            <div className={styles.row} key={index}>
              <Icon InlineIcon={IconDeleteSolid} extraClasses={styles.delIcon} onClick={tagDeleted} />
              <Input
                id={`tag-${index}`}
                name={`tag-${index}`}
                label='Tag'
                value={tag}
                onChange={tagEdited}
                maxLength={200}
                valid={!!tag && /\S/.test(tag)}
              />
            </div>
          );
        })}
      </div>

      <div className={styles.buttonContainer} onClick={onAddTag}>
        <Button appearance={'tertiary'} InlineIcon={IconOfferSolid}>
          Add Tag
        </Button>
      </div>
    </div>
  );
};

export const Tags = React.memo(TagsList);
