import { createSelector } from 'reselect';
import { filterStateSelector, getPriorities, getSelectedFiltersForCurrentView } from './Filters.selectors';
import { INITIATIVE_ACTION_TYPES } from '../../config';
import { createParameterSelector } from '../Helpers';

export const initiativeStateSelector = (state) => state[INITIATIVE_ACTION_TYPES.PREFIX];

export const getRoadMap = createSelector(initiativeStateSelector, (initiativeState) => initiativeState.roadmap);

export const areInitiativesLoading = createSelector(
  filterStateSelector,
  initiativeStateSelector,
  (filterState, initiativeState) => filterState.loadingInitiative || initiativeState.loading
);

export const getTeamInitiatives = createSelector(
  getSelectedFiltersForCurrentView,
  getPriorities,
  getRoadMap,
  (currentFilters, priorities, roadmap) => {
    if (!roadmap) return {};
    if (currentFilters.team === 0) {
      return Object.values(priorities).reduce((acc, priority) => {
        acc[priority.id] = Object.values(roadmap).reduce((acc2, teamPriorityMap) => {
          const initiatives = teamPriorityMap[priority.id] || [];
          acc2 = [...acc2, ...initiatives];
          return acc2;
        }, []);
        return acc;
      }, {});
    }

    return (roadmap && roadmap[currentFilters.team]) || {};
  }
);

const getPriorityId = createParameterSelector((params) => params.priorityId);

export const getInitiativesFromPriorityId = createSelector(
  getTeamInitiatives,
  getPriorityId,
  (initiatives, priorityId) => initiatives[priorityId] || []
);

const getInitiativeId = createParameterSelector((params) => params.initiativeId);

export const getInitiativeFromPriorityIdAndInitiativeId = createSelector(
  getInitiativesFromPriorityId,
  getInitiativeId,
  (initiatives, initiativeId) => initiatives.find((i) => i.id === initiativeId)
);
