import React from 'react';
import {
  Box,
  Button,
  Fieldset,
  Icon,
  IconDeleteSolid,
  IconOfferSolid,
  Input,
  Select,
  SelectGroup,
} from '@flixbus/honeycomb-react';
import styles from './Quarters.module.scss';

const QuartersComp = ({ timeframes, statuses, priorities, setQuarters, quarters }) => {
  const onAddQuarter = () => {
    const mapped = quarters.map((frame) => frame.timeframe.id);

    const available = timeframes.filter((frame) => !mapped.includes(frame.id));

    if (available.length) {
      const payload = [
        ...quarters,
        {
          timeframe: available[0],
          priority: parseInt(priorities[0].id, 10),
          status: parseInt(statuses[0].id, 10),
          status_message: '',
        },
      ];

      setQuarters(payload);
    }
  };

  const isTimeFrameInvalid = (frames) => {
    const tfs = frames.map((frame) => frame.timeframe.id);

    const tfsUnique = Array.from(new Set(tfs));

    return tfs.length !== tfsUnique.length;
  };

  const onEditQuarter = (e) => {
    const fieldName = e.target.name || e.target.id;

    const [field, index] = fieldName.split('-');

    let value = parseInt(e.target.value, 10);

    if (field === 'timeframe') {
      value = timeframes.find((frame) => frame.id === value);
    }

    if (field === 'status_message') {
      value = e.target.value;
    }

    const payload = quarters.map((frame, idx) => {
      if (parseInt(index, 10) === idx) {
        return {
          ...frame,
          [field]: value,
        };
      }
      return frame;
    });

    if (isTimeFrameInvalid(payload)) {
      return alert('Multiple status for same quarter is not allowed');
    }

    setQuarters(payload);
  };

  const onQuarterDeleted = (index) => {
    const payload = quarters.filter((_, idx) => idx !== index);

    payload.length > 0 && setQuarters(payload);
  };

  return (
    <Box extraClasses={styles.box}>
      {quarters.map((frame, index) => {
        const timeframeOptions = [...timeframes].map((item) => ({
          label: item.name,
          value: item.id,
          displayValue: item.name,
        }));

        const statusOptions = [...statuses].map((item) => ({
          label: item.name,
          value: item.id,
          id: `Timeframe-${index}-Status-${item.id}`,
          name: `status-${index}`,
          checked: parseInt(frame.status, 10) === parseInt(item.id, 10),
          onChange: onEditQuarter,
        }));

        const priorityOptions = [...priorities].map((item) => ({
          label: item.name,
          value: item.id,
          id: `Timeframe-${index}-Priority-${item.id}`,
          name: `priority-${index}`,
          checked: parseInt(frame.priority, 10) === parseInt(item.id, 10),
          onChange: onEditQuarter,
        }));

        const quarterDeleted = () => onQuarterDeleted(index);

        return (
          <div className={styles.container} key={index}>
            <Fieldset>
              <div className={styles.row}>
                {index > 0 && (
                  <Icon InlineIcon={IconDeleteSolid} extraClasses={styles.delIcon} onClick={quarterDeleted} />
                )}
                <Select
                  id={`Timeframe-${index}-timeframe`}
                  label='Quarter'
                  name={`timeframe-${index}`}
                  options={timeframeOptions}
                  value={frame.timeframe.id}
                  onChange={onEditQuarter}
                />
              </div>
            </Fieldset>
            <Fieldset>
              <SelectGroup id='priority' label='Priority' options={priorityOptions} multi={false} required />
            </Fieldset>
            <Fieldset>
              <SelectGroup id='status' label='Status' options={statusOptions} multi={false} required />
            </Fieldset>
            <Fieldset style={{ width: '100%' }}>
              <Input
                id={`Timeframe-${index}-status_message`}
                name={`status_message-${index}`}
                label='Status Description'
                value={frame.status_message}
                onChange={onEditQuarter}
                maxLength={200}
              />
            </Fieldset>
          </div>
        );
      })}

      {quarters.length < timeframes.length && (
        <div className={styles.buttonContainer} onClick={onAddQuarter}>
          <Button appearance={'tertiary'} InlineIcon={IconOfferSolid}>
            Add Quarter
          </Button>
        </div>
      )}
    </Box>
  );
};

export const Quarters = React.memo(QuartersComp);
