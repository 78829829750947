import { DEFAULT_FILTER_TYPES } from '../app/Constants';

/**
 * Returns teams department by the given team ID
 * @param teamId
 * @param teams
 * @returns {any}
 */

export const getDepartmentByTeamId = (teamId, teams) => {
  const teamObj = teams.filter((team) => team.id === parseInt(teamId));
  return teamObj.length && teamObj[0].department ? teamObj[0].department : DEFAULT_FILTER_TYPES.department;
};
