import { createSelector } from 'reselect';
import { FILTER_ACTION_TYPES } from '../../config';
import { createParameterSelector } from '../Helpers';

export const filterStateSelector = (state) => state[FILTER_ACTION_TYPES.PREFIX];

export const getFilters = createSelector(filterStateSelector, (filterState) => filterState.filters);

export const getInitiativePayload = createSelector(filterStateSelector, (filterState) => filterState.initiative);

export const getPeople = createSelector(filterStateSelector, (filterState) => filterState.people);

export const areFiltersLoading = createSelector(filterStateSelector, (filterState) => filterState.loadingFilters);

export const getPriorities = createSelector(filterStateSelector, (filterState) => filterState.filters.priority);

export const getCurrentView = createSelector(filterStateSelector, (filterState) => filterState.currentView);

export const getTeams = createSelector(getFilters, (filters) => filters.team);

const getTeamId = createParameterSelector((params) => params.teamId);

export const getTeamFromId = createSelector(getTeams, getTeamId, (teams, teamId) => teams.find((i) => i.id === teamId));

export const getSelectedFiltersForCurrentView = createSelector(
  filterStateSelector,
  getCurrentView,
  (filterState, currentView) => filterState.selectedFilters[currentView]
);
