import React from 'react';

import RoadmapCellItem from '../roadmap-cell-item';
import { Box } from '@flixbus/honeycomb-react';

import styles from './RoadmapCellPreview.module.scss';

const RoadmapCellPreview = ({ width, ...props }) => (
  <Box extraClasses={styles.cellDragging} style={{ width: `${width}px` }}>
    <RoadmapCellItem {...props} />
  </Box>
);

export default React.memo(RoadmapCellPreview);
