import { LoginType, MsalAuthProvider } from 'react-aad-msal';
import { AZURE_AAD, ENVIRONMENT_URI } from '../app/Constants';
import { loggingCallback } from './Logging.util';
import { Logger, LogLevel } from 'msal';

const config = {
  auth: {
    authority: AZURE_AAD.authority,
    clientId: AZURE_AAD.clientId,
    redirectUri: `${ENVIRONMENT_URI}/home`,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: 'https://flix.tech/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    logger: new Logger((logLevel, message) => loggingCallback(logLevel, message), {
      level: LogLevel.Warning,
      piiLoggingEnabled: false,
    }),
  },
};

const scopes = {
  scopes: [...AZURE_AAD.scope, ...AZURE_AAD.graph],
  extraScopesToConsent: [...AZURE_AAD.scope, ...AZURE_AAD.graph],
};

const AuthProvider = new MsalAuthProvider(config, scopes, LoginType.Popup);

export default AuthProvider;
