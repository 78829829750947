import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { APP_ACTION_TYPES, FILTER_ACTION_TYPES, INITIATIVE_ACTION_TYPES } from './Actions.config';
import { AppReducers, FilterReducers, InitiativeReducers } from '../app/reducers';
import {APP_GLOBALS_STORE_KEY} from "../app/actions/appGlobals.actions";
import appGlobalStateReducer from "../app/reducers/appGlobals.reducers";

const filterPersistConfig = {
  key: FILTER_ACTION_TYPES.PREFIX,
  storage: storage,
  whitelist: ['selectedFilters'],
};

const appGlobalsPersistConfig = {
  key: APP_GLOBALS_STORE_KEY,
  storage,
  blacklist: [],
};

const ReducerConfig = (history) =>
  combineReducers({
    // TODO: remove everything expect globals and move the rest of the data to react-query
    [APP_GLOBALS_STORE_KEY]: persistReducer(appGlobalsPersistConfig, appGlobalStateReducer),
    [APP_ACTION_TYPES.PREFIX]: AppReducers,
    [FILTER_ACTION_TYPES.PREFIX]: persistReducer(filterPersistConfig, FilterReducers),
    [INITIATIVE_ACTION_TYPES.PREFIX]: InitiativeReducers,
    router: connectRouter(history),
  });

export default ReducerConfig;
