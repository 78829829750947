import { fork } from 'redux-saga/effects';

import apiSaga from '../app/sagas/Api.saga';
import appSaga from '../app/sagas/App.saga';
import filterSaga from '../app/sagas/Filters.saga';
import initiativeSaga from '../app/sagas/Initiative.saga';

function* SagaConfig() {
  yield fork(apiSaga);
  yield fork(appSaga);
  yield fork(filterSaga);
  yield fork(initiativeSaga);
}

export default SagaConfig;
