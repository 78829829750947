import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  Icon,
  IconDelete,
  IconOfferSolid,
  Input,
  Legend,
} from '@flixbus/honeycomb-react';
import styles from './Milestones.module.scss';

const MilestonesComp = ({ milestones = [], setMilestones }) => {
  const onAddMilestone = () => {
    setMilestones([...milestones, { name: '', concluded: false }]);
  };

  const onEditMilestone = (milestone, idx) => {
    const payload = milestones.map((ms, index) => {
      if (idx === index) {
        return milestone;
      }

      return ms;
    });
    setMilestones(payload);
  };

  const onMilestoneDeleted = (index) => {
    const payload = milestones.filter((_, idx) => idx !== index);
    setMilestones(payload);
  };

  return (
    <Box extraClasses={styles.box}>
      {milestones.map((milestone, index) => {
        const milestoneEdited = (e) => onEditMilestone({ ...milestone, name: e.target.value }, index);
        const milestoneDeleted = () => onMilestoneDeleted(index);
        const milestoneConcluded = (e) => onEditMilestone({ ...milestone, concluded: e.target.checked }, index);

        return (
          <div className={styles.container} key={index}>
            <div className={styles.row}>
              <Icon InlineIcon={IconDelete} extraClasses={styles.delIcon} onClick={milestoneDeleted} />
              <Fieldset style={{ flex: 1, marginRight: 12 }}>
                <Input
                  id={`milestone-${index}`}
                  name={`milestone-${index}`}
                  label='Title'
                  value={milestone.name}
                  onChange={milestoneEdited}
                  maxLength={200}
                  valid={!!milestone.name && /\S/.test(milestone.name)}
                />
              </Fieldset>
              <Fieldset>
                <div>
                  <Legend>Done</Legend>
                  <Checkbox
                    aria-label={`milestone-${index}-concluded`}
                    defaultChecked={milestone.concluded}
                    id={`milestone-${index}-concluded`}
                    value={`milestone-${index}-concluded`}
                    onChange={milestoneConcluded}
                  />
                </div>
              </Fieldset>
            </div>
          </div>
        );
      })}

      <div className={styles.buttonContainer} onClick={onAddMilestone}>
        <Button appearance={'tertiary'} InlineIcon={IconOfferSolid}>
          Add Milestone
        </Button>
      </div>
    </Box>
  );
};

export const Milestones = React.memo(MilestonesComp);
