import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { APP_ACTION_TYPES } from '../../config';
import { DEFAULT_APP_STATE } from '../Constants';
import { bufferToBase64 } from './Helpers';

export default handleActions(
  new Map([
    [
      APP_ACTION_TYPES.SET_DEVICE_DATA,
      (state, { payload }) =>
        update(state, {
          deviceData: { $set: payload && payload['deviceData'] },
        }),
    ],
    [
      APP_ACTION_TYPES.SET_PAGE,
      (state, { payload }) =>
        update(state, {
          currentPage: { $set: payload && payload['currentPage'] },
        }),
    ],
    [
      APP_ACTION_TYPES.SHOW_DELETE_POPUP,
      (state) =>
        update(state, {
          showDeletePopup: { $set: true },
        }),
    ],
    [
      APP_ACTION_TYPES.HIDE_DELETE_POPUP,
      (state) =>
        update(state, {
          showDeletePopup: { $set: false },
        }),
    ],
    [
      APP_ACTION_TYPES.SET_AUTH_DATA,
      (state, { payload }) =>
        update(state, {
          authState: { $set: payload },
        }),
    ],
    [
      APP_ACTION_TYPES.SET_PROFILE_PICTURE,
      (state, { data }) =>
        update(state, {
          profile: {
            $set: {
              photo: bufferToBase64(data),
            },
          },
        }),
    ],
    [
      APP_ACTION_TYPES.REQUEST_ERROR,
      (state, { meta }) =>
        update(state, {
          error: { $set: meta.statusText },
        }),
    ],
  ]),
  DEFAULT_APP_STATE
);
