class Timeout {
  constructor() {
    this.timeouts = [];
  }

  setTimeout() {
    this.timeouts.push(setTimeout.apply(null, arguments));
  }

  clearTimeouts() {
    this.timeouts.forEach(clearTimeout);
  }
}

export default Timeout;
