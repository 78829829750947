import { applyMiddleware, compose, createStore } from 'redux';
// noinspection ES6CheckImport
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ReducerConfig, SagaConfig } from './';
import { routerMiddleware } from 'connected-react-router';

export const PERSIST_CONFIG = {
  key: 'root',
  storage,
  whitelist: [],
};

const StoreConfig = (history, initialState = {}) => {
  const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

  const sagaMiddleware = createSagaMiddleware();

  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)));

  const reducer = ReducerConfig(history);
  const persistedReducer = persistReducer(PERSIST_CONFIG, reducer);
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);

  sagaMiddleware.run(SagaConfig);

  if (module['hot'] && process.env.NODE_ENV !== 'production') {
    // noinspection JSCheckFunctionSignatures
    module['hot'].accept('./Reducer.config', () => store.replaceReducer(require('./Reducer.config')));
  }

  return { store, persistor };
};

export default StoreConfig;
