import {
  Button,
  Grid,
  GridCol,
  Heading,
  Popup,
  PopupSection,
  FormControl,
  IconSaveSolid,
  IconClose,
} from '@flixbus/honeycomb-react';
import React, { useEffect, useState } from 'react';

import { getEpicValidationState } from '../../../Helpers';
import { EpicForm } from './form/EpicForm';

import styles from './EpicFormContainer.module.scss';

const EpicFormContainerComp = ({ actions, actionState, epic, filters, selectedFilters, people }) => {
  const [localEpic, setLocalEpic] = useState(epic);
  const [validationState, setValidationState] = useState(getEpicValidationState(epic));

  useEffect(() => {
    setLocalEpic(epic);
    setValidationState(getEpicValidationState(epic));
  }, [epic]);

  const [showDeletePopup, setShowDeletePopUp] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    if (!!actionState.error) {
      triggerShowErrorPopup(true);
    }
  }, [actionState.error]);

  const triggerShowDeletePopup = () => setShowDeletePopUp(true);
  const triggerCloseDeletePopup = () => setShowDeletePopUp(false);

  const triggerShowErrorPopup = () => setShowErrorPopup(true);
  const triggerCloseErrorPopup = () => {
    actions.clearError();
    setShowErrorPopup(false);
  };

  const onEpicUpdated = (field, value) => {
    const epicPayload = {
      ...localEpic,
      [field]: value,
    };
    setLocalEpic(epicPayload);
    setValidationState(getEpicValidationState(epicPayload));
  };

  const saveEpic = () => actions.onSave(localEpic);

  const saveEpicAndGoBack = () => actions.onSave(localEpic, true);

  const isActionInProgress = actionState.isDeleting || actionState.isSaving;

  const isEpicNotValid = !validationState.valid;

  return (
    <div className={styles.container}>
      <Grid justify='right'>
        <GridCol size={10}>
          {localEpic && (
            <EpicForm
              epic={localEpic}
              onEpicChange={onEpicUpdated}
              filters={filters}
              people={people}
              searchPeople={actions.searchPeople}
              selectedFilters={selectedFilters}
            />
          )}
        </GridCol>
        <GridCol size={2}>
          <div className={styles.buttonsRow}>
            <FormControl extraClasses={styles.buttonsControl}>
              <Button
                InlineIcon={IconSaveSolid}
                appearance='primary'
                onClick={saveEpic}
                loading={actionState.isSaving}
                disabled={isActionInProgress || isEpicNotValid}
              >
                Save
              </Button>
            </FormControl>
            <FormControl extraClasses={styles.buttonsControl}>
              <Button InlineIcon={IconClose} onClick={() => actions.goBack()}>
                Cancel
              </Button>
            </FormControl>
            <FormControl extraClasses={styles.buttonsControl}>
              <Button
                onClick={saveEpicAndGoBack}
                loading={actionState.isSaving}
                disabled={isActionInProgress || isEpicNotValid}
              >
                Save & go back
              </Button>
            </FormControl>
            {actionState.isEditMode && (
              <FormControl extraClasses={styles.buttonsControl}>
                <Button
                  appearance={'danger'}
                  onClick={triggerShowDeletePopup}
                  loading={actionState.isDeleting}
                  disabled={isActionInProgress || isEpicNotValid}
                >
                  Delete initiative
                </Button>
              </FormControl>
            )}
          </div>
        </GridCol>
      </Grid>

      <Popup active={actionState.isEditMode && showDeletePopup} extraClasses={styles.popupBody}>
        <PopupSection type='title'>
          <Heading size={1}>Delete initiative?</Heading>
        </PopupSection>
        <PopupSection type='actions'>
          <Button appearance={'tertiary'} onClick={triggerCloseDeletePopup}>
            No, don’t delete
          </Button>
          <Button
            appearance={'danger'}
            onClick={actions.onDelete}
            loading={actionState.isSaving}
            disabled={isActionInProgress}
          >
            Yes, delete
          </Button>
        </PopupSection>
      </Popup>

      <Popup active={!!actionState.error && showErrorPopup} extraClasses={styles.popupBody}>
        <PopupSection type='title'>
          <Heading size={1}>Oh No!</Heading>
          <Heading size={3}>&nbsp;{actionState.error}</Heading>
        </PopupSection>
        <PopupSection type='actions'>
          <Button appearance={'tertiary'} onClick={triggerCloseErrorPopup}>
            Okay
          </Button>
        </PopupSection>
      </Popup>
    </div>
  );
};

export const EpicFormContainer = React.memo(EpicFormContainerComp);
