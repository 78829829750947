import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { withResizeDetector } from 'react-resize-detector';
import { Box } from '@flixbus/honeycomb-react';

import { ITEM_TYPES } from '../Constants';
import RoadmapCellItem from './roadmap-cell-item';

import styles from './RoadmapCell.module.scss';

const RoadmapCellComp = ({
  cellIndex,
  cellId,
  cellColumn,
  initiative,
  priority,
  selectedFilters,
  removeInitiative,
  showInitiativeViewPanel,
}) => {
  const cellRef = useRef(null);

  // useDrag will be triggered in the starting column
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ITEM_TYPES.INITIATIVE,
      initiative,
      selectedFilters,
      showInitiativeViewPanel,
      cellId,
      cellIndex,
      cellColumn,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    // not allow drag-n-drop if all teams view or search filter applied
    canDrag: () => selectedFilters.team !== 0 && !selectedFilters.search,
    end: (item, monitor) => {
      if (!monitor.didDrop()) return false;

      cellColumn !== monitor.getDropResult().droppedColumn && removeInitiative(initiative);
    },
  });

  // this will be triggered in the dropped column
  const [, drop] = useDrop({
    accept: ITEM_TYPES.INITIATIVE,
    hover: (item, monitor) => {
      if (!cellRef.current) return;

      const dragIndex = item.cellIndex;
      const hoverIndex = cellIndex;
      const sourceCellId = monitor.getItem().cellId;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && cellId === item.cellId) {
        return;
      }

      const hoverBoundingRect = cellRef.current.getBoundingClientRect(); // Determine rectangle on screen
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2; // Get vertical middle
      const clientOffset = monitor.getClientOffset(); // Determine mouse position
      const hoverClientY = clientOffset.y - hoverBoundingRect.top; // Get pixels to the top

      // Only perform the move when the mouse has crossed half of the items height
      // Dragging downwards, only move when the cursor is below 50%
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards, only move when the cursor is above 50%
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      if (cellId !== sourceCellId) {
        // Note: we're mutating the monitor item here! Generally it's better to avoid mutations,
        // but it's good here for the sake of performance to avoid expensive index searches.
        monitor.getItem().droppedIndex = hoverIndex;
        monitor.getItem().droppedColumn = priority;
        monitor.getItem().droppedOverCell = cellId;
      }
    },
  });

  useEffect(() => preview(getEmptyImage(), { captureDraggingState: true }) && (() => null), [preview]);

  drag(drop(cellRef));

  return (
    <div ref={cellRef} className={styles.dropZone}>
      <Box extraClasses={cn(styles.item, { [styles.isDraggingItem]: isDragging })}>
        <RoadmapCellItem
          initiative={initiative}
          selectedFilters={selectedFilters}
          showInitiativeViewPanel={showInitiativeViewPanel}
        />
      </Box>
    </div>
  );
};

const RoadmapCell = React.memo(RoadmapCellComp);

export default withResizeDetector(RoadmapCell);
