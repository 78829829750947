import { put, select, takeLatest } from 'redux-saga/effects';
import { FILTER_ACTIONS, INITIATIVE_ACTIONS } from '../actions';
import { push } from 'react-router-redux';
import { APP_PAGES } from '../Constants';
import { getSelectedFiltersForCurrentView } from '../selectors';

export function* syncPayload({ payload }) {
  yield put(payload);
}

export function* setFilter({ payload }) {
  yield put(payload);

  if (payload.payload.field && payload.payload.field === 'toggles') return;

  if (payload.payload && typeof payload.payload.reload === 'boolean' && payload.payload.reload === false) return;

  const { timeframe, team, ...selectedFilters } = yield select(getSelectedFiltersForCurrentView);

  yield put(INITIATIVE_ACTIONS.triggerFetchInitiatives({ timeframe, team, ...selectedFilters }).payload);

  if (payload.payload && typeof payload.payload.change === 'boolean' && payload.payload.change === false) return;

  yield put(push(APP_PAGES.home.path(timeframe, team)));
}

export default function* filterSaga() {
  yield takeLatest(FILTER_ACTIONS.triggerFetchFilters.toString(), syncPayload);
  yield takeLatest(FILTER_ACTIONS.triggerSetCurrentView.toString(), syncPayload);
  yield takeLatest(FILTER_ACTIONS.triggerSetFilter.toString(), setFilter);
  yield takeLatest(FILTER_ACTIONS.triggerSetPeople.toString(), syncPayload);
  yield takeLatest(FILTER_ACTIONS.triggerSetFilters.toString(), setFilter);
  yield takeLatest(FILTER_ACTIONS.triggerSearchPeople.toString(), syncPayload);
  yield takeLatest(FILTER_ACTIONS.triggerSetCreateInitiativeField.toString(), syncPayload);
  yield takeLatest(FILTER_ACTIONS.triggerSetInitiative.toString(), syncPayload);
}
