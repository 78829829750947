import { Header as HoneyCombHeader } from '@flixbus/honeycomb-react';
import React, {useState} from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { APP_PAGES } from '../../Constants';
import { getSelectedFiltersForCurrentView } from '../../selectors';

import HeaderProfile from './header-profile/HeaderProfile';
import { HEADER_BRAND } from './Constants';

import styles from './Header.module.scss';

const Header: React.FC<any> = ({ authenticationState, login, logout, ...props }) => {
  const { pathname } = useLocation();
  const { timeframe, team } = useSelector(getSelectedFiltersForCurrentView);
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const isLoggedIn = authenticationState === AuthenticationState.Authenticated;
  const navigation = [
    {
      content: 'Dependencies',
      href: APP_PAGES.dependencyDashboard.path(),
      current: pathname === APP_PAGES.dependencyDashboard.path(),
    },
  ];

  return (
    <HoneyCombHeader
      navigation={[]}
      navigationLabel="Main navigation"
      toggleMenu={() => {
        setIsMobileNavActive(!isMobileNavActive);
      }}
      toggleMenuBtnLabel="Show main navigation"
      fixed={false}
      brand={HEADER_BRAND({ timeframe, team, redirectToRoot: isLoggedIn === false })}
      widget={<HeaderProfile login={login} logout={logout} isLoggedIn {...props} />}
      stickyWidget={true}
      extraClasses={styles.appHeader}
      menuVisible={isMobileNavActive}
    />
  );
};

export default Header;
