export const API_ACTION_TYPES = {
  TRIGGER_API_REQUEST: 'TRIGGER_API_REQUEST',
  GET_METHOD: 'GET',
  POST_METHOD: 'POST',
  PUT_METHOD: 'PUT',
  DELETE_METHOD: 'DELETE',
};

export const APP_ACTION_TYPES = {
  PREFIX: 'app',
  TRIGGER_SET_DEVICE_DATA: 'TRIGGER_SET_DEVICE_DATA',
  SET_DEVICE_DATA: 'SET_DEVICE_DATA',
  TRIGGER_SET_PAGE: 'TRIGGER_SET_PAGE',
  SET_PAGE: 'SET_PAGE',
  TRIGGER_SHOW_DELETE_POPUP: 'TRIGGER_SHOW_DELETE_POPUP',
  SHOW_DELETE_POPUP: 'SHOW_DELETE_POPUP',
  TRIGGER_HIDE_DELETE_POPUP: 'TRIGGER_HIDE_DELETE_POPUP',
  HIDE_DELETE_POPUP: 'HIDE_DELETE_POPUP',
  TRIGGER_SET_AUTH_DATA: 'TRIGGER_SET_AUTH_DATA',
  SET_AUTH_DATA: 'SET_AUTH_DATA',
  TRIGGER_SET_PROFILE_DATA: 'TRIGGER_SET_PROFILE_DATA',
  SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
  REQUEST_ERROR: 'REQUEST_ERROR',
};

export const FILTER_ACTION_TYPES = {
  PREFIX: 'filters',
  TRIGGER_SET_LOADING_STATE: 'TRIGGER_SET_LOADING_STATE',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  TRIGGER_FETCH_FILTERS: 'TRIGGER_FETCH_FILTERS',
  FETCH_FILTERS_STARTED: 'FETCH_FILTERS_STARTED',
  FETCH_FILTERS_SUCCESS: 'FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAILURE: 'FETCH_FILTERS_FAILURE',
  TRIGGER_SET_CURRENT_VIEW: 'TRIGGER_SET_CURRENT_VIEW',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  TRIGGER_SET_FILTER: 'TRIGGER_SET_FILTER',
  SET_FILTER: 'SET_FILTER',
  TRIGGER_SET_FILTERS: 'TRIGGER_SET_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  TRIGGER_SET_CREATE_INITIATIVE_FIELD: 'TRIGGER_SET_CREATE_INITIATIVE_FIELD',
  SET_CREATE_INITIATIVE_FIELD: 'SET_CREATE_INITIATIVE_FIELD',
  TRIGGER_SET_INITIATIVE: 'TRIGGER_SET_INITIATIVE',
  SET_INITIATIVE: 'SET_INITIATIVE',
  TRIGGER_SET_PEOPLE: 'TRIGGER_SET_PEOPLE',
  SET_PEOPLE: 'SET_PEOPLE',
  TRIGGER_FETCH_INITIATIVE: 'TRIGGER_FETCH_INITIATIVE',
  FETCH_INITIATIVE_STARTED: 'FETCH_INITIATIVE_STARTED',
  FETCH_INITIATIVE_SUCCESS: 'FETCH_INITIATIVE_SUCCESS',
  FETCH_INITIATIVE_FAILURE: 'FETCH_INITIATIVE_FAILURE',
  TRIGGER_SEARCH_PEOPLE: 'TRIGGER_SEARCH_PEOPLE',
  SEARCH_PEOPLE_STARTED: 'SEARCH_PEOPLE_STARTED',
  SEARCH_PEOPLE_SUCCESS: 'SEARCH_PEOPLE_SUCCESS',
  SEARCH_PEOPLE_FAILURE: 'SEARCH_PEOPLE_FAILURE',
};

export const INITIATIVE_ACTION_TYPES = {
  PREFIX: 'initiatives',
  TRIGGER_FETCH_INITIATIVES: 'TRIGGER_FETCH_INITIATIVES',
  FETCH_INITIATIVES_STARTED: 'FETCH_INITIATIVES_STARTED',
  FETCH_INITIATIVES_SUCCESS: 'FETCH_INITIATIVES_SUCCESS',
  FETCH_INITIATIVES_FAILURE: 'FETCH_INITIATIVES_FAILURE',
  TRIGGER_CREATE_INITIATIVE: 'TRIGGER_CREATE_INITIATIVE',
  CREATE_INITIATIVE_STARTED: 'CREATE_INITIATIVE_STARTED',
  CREATE_INITIATIVE_SUCCESS: 'CREATE_INITIATIVE_SUCCESS',
  CREATE_INITIATIVE_FAILURE: 'CREATE_INITIATIVE_FAILURE',
  TRIGGER_EDIT_INITIATIVE: 'TRIGGER_EDIT_INITIATIVE',
  EDIT_INITIATIVE_STARTED: 'EDIT_INITIATIVE_STARTED',
  EDIT_INITIATIVE_SUCCESS: 'EDIT_INITIATIVE_SUCCESS',
  EDIT_INITIATIVE_FAILURE: 'EDIT_INITIATIVE_FAILURE',
  TRIGGER_DRAG_INITIATIVE: 'TRIGGER_DRAG_INITIATIVE',
  DRAG_INITIATIVE_STARTED: 'DRAG_INITIATIVE_STARTED',
  DRAG_INITIATIVE_SUCCESS: 'DRAG_INITIATIVE_SUCCESS',
  DRAG_INITIATIVE_FAILURE: 'DRAG_INITIATIVE_FAILURE',
  TRIGGER_ADD_INITIATIVE: 'TRIGGER_ADD_INITIATIVE',
  TRIGGER_DELETE_INITIATIVE: 'TRIGGER_DELETE_INITIATIVE',
  DELETE_INITIATIVE_STARTED: 'DELETE_INITIATIVE_STARTED',
  DELETE_INITIATIVE_SUCCESS: 'DELETE_INITIATIVE_SUCCESS',
  DELETE_INITIATIVE_FAILURE: 'DELETE_INITIATIVE_FAILURE',
};
