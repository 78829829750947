import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { LoadingSpinner } from '../../shared';
import { Timeout } from '../../utils';
import { APP_ACTIONS, FILTER_ACTIONS, INITIATIVE_ACTIONS } from '../actions';
import Filters from '../components/filters';
import { ViewInitiativePanel } from '../components/initiative';
import Kpis from '../components/kpis';
import Priorities from '../components/priorities';
import Roadmap from '../components/roadmap';
import { APP_PAGES, DATE_FORMAT } from '../Constants';
import { PageContainer, Heading, Text } from '@flixbus/honeycomb-react';
import {
  areFiltersLoading,
  getFilters,
  getInitiativePayload,
  getPeople,
  getPriorities,
  getSelectedFiltersForCurrentView,
} from '../selectors';

import styles from './Home.module.scss';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = { headerStyle: false };
  }

  updateView = () => {
    const { selectedFilters } = this.props;

    const {
      match: {
        params: { timeframe = selectedFilters.timeframe, team = selectedFilters.team, initiativeId },
      },
      filters: { timeframe: timeframes, team: teams, department: departments },
    } = this.props;

    this.props.actions.triggerSetPage(APP_PAGES.home.path());

    !!initiativeId && this.goToInitiativeView(initiativeId);

    timeframes.length === 0 && timeframes.push({ slug: 'None' });
    teams.length === 0 && teams.push({ id: 0 });
    departments.length === 0 && departments.push({ id: 0 });

    this.props.actions.triggerSetFilters({
      timeframe: (timeframes.find((item) => item.slug === timeframe) || timeframes[0]).slug,
      team: (teams.find((item) => item.id === parseInt(team)) || teams[0]).id,
      change: [undefined, NaN, 0, null].includes(initiativeId),
    });
  };

  componentDidUpdate = (prevProps) => prevProps.filters !== this.props.filters && this.updateView();

  componentDidMount = () => window.addEventListener('scroll', this.handleScroll);

  componentWillUnmount = () => window.removeEventListener('scroll', this.handleScroll);

  goToInitiativeForm = (initiativeId) => {
    const { selectedFilters } = this.props;
    this.props.history.push(APP_PAGES.form.path(selectedFilters.team, initiativeId));
  };

  goToInitiativeView = (initiativeId) =>
    this.props.actions.triggerFetchInitiative({ id: initiativeId }) &&
    this.props.history.push(APP_PAGES.view.path(initiativeId));

  addInitiative = () => this.goToInitiativeForm();

  editInitiative = (initiativeId) => this.goToInitiativeForm(initiativeId);

  dragInitiative = (initiative) => this.props.actions.triggerDragInitiative(initiative);

  handleScroll = () => this.setState({ headerStyle: window.scrollY > 60 });

  render() {
    const {
      actions,
      accountInfo,
      filters,
      priorities,
      selectedFilters,
      initiative,
      history,
      loading,
      match: {
        params: { initiativeId },
      },
    } = this.props;

    const { headerStyle } = this.state;

    const filterActions = {
      setCurrentView: actions.triggerSetCurrentView,
      setFilter: actions.triggerSetFilter,
    };

    const closeInitiativeView = (timeouts) => {
      timeouts.clearTimeouts();

      history.push(APP_PAGES.home.path(selectedFilters.timeframe, selectedFilters.team));
    };

    const teamInfo = selectedFilters.team !== 0 && filters.team.find((team) => team.id === selectedFilters.team);

    return (
      (loading && <LoadingSpinner />) || (
        <>
          <div className={[styles.headContainer, headerStyle ? styles.sticky : styles.normal].join(' ')}>
            <PageContainer>
              <Filters
                filters={filters}
                selectedFilters={selectedFilters}
                showCreateInitiativePage={this.addInitiative}
                filterActions={filterActions}
              />
              {teamInfo && (
                <div className={styles.printInformation}>
                  <Heading size={1} flushSpace>
                    Team {teamInfo.name}
                  </Heading>
                  {teamInfo.department && (
                    <Heading size={2} flushSpace>
                      {teamInfo.department.name}
                    </Heading>
                  )}
                  <Text>
                    Last Updated: {new Date(teamInfo.updated_at).toLocaleDateString(undefined, DATE_FORMAT)}
                    {' - '}
                    {teamInfo.developers} Developers (FTEs)
                  </Text>
                </div>
              )}
              {selectedFilters.team !== 0 && teamInfo && <Kpis team={teamInfo} />}
              <Priorities priorities={priorities} />
            </PageContainer>
          </div>
          {initiativeId && initiative.id && (
            <ViewInitiativePanel
              timeouts={new Timeout()}
              initiative={initiative}
              showEditInitiativePage={this.editInitiative}
              closeInitiativeView={closeInitiativeView}
            />
          )}
          <PageContainer extraClasses={`${styles.roadmapContainer} ${[headerStyle ? styles.extraSize : ''].join(' ')}`}>
            <Roadmap
              editInitiative={this.dragInitiative}
              showInitiativeViewPanel={this.goToInitiativeView}
              createdBy={(accountInfo && accountInfo.account.userName) || initiative.createdBy}
            />
          </PageContainer>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: areFiltersLoading(state),
    initiative: getInitiativePayload(state),
    people: getPeople(state),
    priorities: getPriorities(state),
    filters: getFilters(state),
    selectedFilters: getSelectedFiltersForCurrentView(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        triggerSetPage: APP_ACTIONS.triggerSetPage,
        triggerSetFilters: FILTER_ACTIONS.triggerSetFilters,
        triggerSetFilter: FILTER_ACTIONS.triggerSetFilter,
        triggerSetCurrentView: FILTER_ACTIONS.triggerSetCurrentView,
        triggerFetchInitiatives: INITIATIVE_ACTIONS.triggerFetchInitiatives,
        triggerFetchInitiative: FILTER_ACTIONS.triggerFetchInitiative,
        triggerEditInitiative: INITIATIVE_ACTIONS.triggerEditInitiative,
        triggerDragInitiative: INITIATIVE_ACTIONS.triggerDragInitiative,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
