import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import { AuthenticationState, AzureAD, IAzureADFunctionProps } from 'react-aad-msal';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import { Login } from './app/pages';
import { AxiosProvider } from './utils/transport/axios.context';
import { StoreConfig } from './config';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './utils';

import './index.module.scss';
import './tailwind.css';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export const history = createBrowserHistory();
export const { store, persistor } = StoreConfig(history);
const queryClient = new QueryClient();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeWrapper theme="default">
            <MainWrapper full>
              <Router history={history}>
                <AzureAD provider={AuthProvider} forceLogin={false}>
                  {(azureADProps: IAzureADFunctionProps) => {
                    if (azureADProps.authenticationState === AuthenticationState.Unauthenticated) {
                      return <Login login={azureADProps.login} />;
                    }

                    return (
                      // TODO: Replace saga api calls and redux with react query
                      <AxiosProvider token={azureADProps.accountInfo?.jwtAccessToken}>
                        <QueryClientProvider client={queryClient}>
                          <App {...azureADProps} />
                        </QueryClientProvider>
                      </AxiosProvider>
                    );
                  }}
                </AzureAD>
              </Router>
            </MainWrapper>
          </ThemeWrapper>
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

render();

if (module['hot'] && process.env.NODE_ENV !== 'production') {
  module['hot'].accept('./app/App', () => {
    render();
  });
}
