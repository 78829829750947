import { DataTable, Heading, Icon, IconCheckmark, IconClose } from '@flixbus/honeycomb-react';
import React from 'react';

const Milestones = ({styles, milestones}) => {
  const milestoneRows = milestones.map((a) => {
    const icon = a.concluded
      ? <Icon InlineIcon={IconCheckmark} title='Yes' />
      : <Icon InlineIcon={IconClose} title='No' />;

    return {cells: [icon, a.name]};
  });

  return milestoneRows.length > 0 && (
    <div className={styles.panelTable}>
      <Heading size={3}>Milestones:</Heading>
      <DataTable small rows={milestoneRows} headers={{cells: ['Concluded', 'Milestone Name']}} />
    </div>
  )
}

export default Milestones;
