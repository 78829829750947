import { PageContainer } from '@flixbus/honeycomb-react';
import React from 'react';

import { DependencyFilters } from './dependency-filters/DependencyFilters';
import { DependencyReport } from './dependency-report/DependencyReport';
import { DependencyTable } from './dependency-table/DependencyTable';

import styles from './DependencyDashboard.module.scss';

const DependencyDashboardComp: React.FC = () => {
  return (
    <PageContainer extraClasses={styles.pageContainer}>
      <DependencyFilters />
      <DependencyReport />
      <DependencyTable />
    </PageContainer>
  );
};

export const DependencyDashboard = React.memo(DependencyDashboardComp);
