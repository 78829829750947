import React from 'react';
import { useDragLayer } from 'react-dnd';

import RoadmapCellPreview from '../roadmap-cell/roadmap-cell-preview';
import styles from './RoadmapDragLayer.module.scss';

const RoadmapDragLayer = () => {
  const { item, initialOffset, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getDragOffset = (initialOffset, currentOffset) => {
    if (!initialOffset || !currentOffset) return { display: 'none' };

    const { x, y } = currentOffset;

    return { transform: `translate(${x}px, ${y}px)`, WebkitTransform: `translate(${x}px, ${y}px)` };
  };

  if (!isDragging) return null;

  return (
    <div className={styles.dragLayer}>
      <div style={getDragOffset(initialOffset, currentOffset)}>
        <RoadmapCellPreview {...item} />
      </div>
    </div>
  );
};

export default React.memo(RoadmapDragLayer);
