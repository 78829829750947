import React from 'react';
import { DataTable, Heading, Link } from '@flixbus/honeycomb-react';
import { getAssigneeName } from '../../../Helpers';
import { DEFAULT_FILTER_TYPES, DELVE, SHIRT_SIZES } from '../../../Constants';

const InitiativeDetails = ({styles, assignees, teams, initiativeSize, initiativeTeams}) => {
  const listAssignees = assignees.map((assignee, index) => {
    const splitName = getAssigneeName(assignee);

    return (
      <Link target='_blank' rel='noopener noreferrer' href={DELVE(assignee)} key={index}>
        {splitName[0]} {splitName[1]}
        {assignees.length - index > 1 && `, `}
      </Link>
    );
  });

  const team = teams.find((team) => team.id === initiativeTeams[0]) || DEFAULT_FILTER_TYPES.team;

  const size = Object.keys(SHIRT_SIZES).find((item) => SHIRT_SIZES[item] === initiativeSize) || 'No size defined';

  const detailsRows = [
    {cells: ['Size', size.replace('_', ' ').toLowerCase()]},
    {cells: ['Team', team.name.toLowerCase()]},
    {cells: ['Assignees', (listAssignees.length > 0 && listAssignees) || 'No Assignees']},
  ];

  return (
    <div className={styles.panelTable}>
      <Heading size={3}>Details:</Heading>
      <DataTable small rows={detailsRows} headers={{cells: []}} />
    </div>
  )
}

export default InitiativeDetails;
