import ProfileImg from '../assets/imgs/profile.png';

export const BASE_URL = process.env.REACT_APP_API_HOST || 'http://localhost:8084/backend/api';

export const DASHBOARD_URL = BASE_URL.replace('api', 'dashboard');

export const GRAPH_URL = `https://graph.microsoft.com/v1.0`;

export const ENVIRONMENT_URI = window.location.origin;

export const VIEW_TYPES = ['Domain Roadmap', 'Country Initiative Roadmap', 'KPI Roadmap'];

export const ALLOWED_FILTER_PARAMS_FOR_API = ['team', 'timeframe'];

export const DRAGGING_ALLOWED_KEYS = ['status', 'priority', 'position'];

export const DELVE = (email: string) => `https://delve.office.com/?p=${email}&v=work`;

export const DATE_FORMAT = { year: '2-digit', month: '2-digit', day: '2-digit' };

export const SHIRT_SIZES = {
  NO_SIZE: 'N/A',
  EXTRA_SMALL: 'XS',
  SMALL: 'S',
  MEDIUM: 'M',
  LARGE: 'L',
  EXTRA_LARGE: 'XL',
};

export const APP_PAGES = {
  root: { path: () => `/`, regex: ['/', '/home'] },
  home: { path: (timeframe: string, team: string) => `/${timeframe}/${team}`, regex: ['/:timeframe/:team(\\d+)'] },
  view: {
    path: (initiativeId: number) => `/initiatives/view/${initiativeId}`,
    regex: [`/initiatives/view/:initiativeId(\\d+)`],
  },
  dependencyDashboard: {
    path: () => `/dependencies`,
    regex: [`/dependencies`],
  },
  form: {
    path: (teamId: number, epic: number) => `/team/${teamId}/epic/${epic ? `edit/${epic}` : `new`}`,
    regex: [`/team/:teamId(\\d+)/epic/:mode(new|edit)/:epicId(\\d+)?`],
  },
  login: { path: () => `/login`, regex: ['/login'] },
  logout: { path: () => `/logout`, regex: ['/logout'] },
  notFound: { path: () => `/404`, regex: ['/404', '*'] },
};

export const BACKEND_PAGES = {
  editTeam: (id: number) => `${DASHBOARD_URL}/team/${id}/edit`,
  editInitiative: (id: number) => `${DASHBOARD_URL}/initiative/${id}/edit`,
};

export const API_ENDPOINTS = {
  dragInitiative: (id: number, timeframe: string) => `${BASE_URL}/initiative/drop/${id}/${timeframe}`,
  getRoadMap: (timeframe: string) => `${BASE_URL}/initiatives/${timeframe}`,
  deleteInitiative: (id: number) => `${BASE_URL}/initiative/${id}`,
  editInitiative: (id: number) => `${BASE_URL}/initiative/${id}`,
  getInitiative: (id: number) => `${BASE_URL}/initiative/${id}`,
  createInitiative: () => `${BASE_URL}/initiative`,
  getFilters: () => `${BASE_URL}/filteroptions`,
  getDependencies: (q?: string) => `${BASE_URL}/dependencies?${q ? q : ''}`,
  getDependencyReport: (q?: string) => `${BASE_URL}/dependency-report?${q ? q : ''}`,
};

export const GRAPH_ENDPOINTS = {
  getPeople: (query: string) =>
    `${GRAPH_URL}/users?$filter=startswith(displayName,'${query}')&$select=displayName,mail,id`,
  getProfilePicture: () => `${GRAPH_URL}/me/photo/$value`,
};

export const AZURE_AAD = {
  authority:
    process.env.REACT_APP_AZURE_AUTHORITY_URL ||
    'https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5',
  clientId: process.env.REACT_APP_AZURE_APPLICATION_ID || '08b0ecd5-cde7-43bc-adb1-376f9c96cc22',
  scope: process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_SCOPES
    ? process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_SCOPES.split(',')
    : [],
  graph: process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_GRAPH
    ? process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_GRAPH.split(',')
    : [],
};

export const DEFAULT_FILTER_TYPES = {
  timeframe: {
    slug: '2000-0',
    name: 'None',
    id: 0,
  },
  department: {
    name: 'Others',
    updated_at: null,
    id: 0,
  },
  team: {
    name: 'All Teams',
    department: null,
    developers: 0,
    updated_at: null,
    id: 0,
  },
  people: {
    results: [],
    query: '',
  },
  priority: {
    id: '1',
    name: 'Now',
  },
  status: {
    id: '1',
    name: 'Idea',
  },
  selected: {
    team: 0,
    toggles: {
      description: false,
    },
    timeframe: '2000-0',
    search: '',
  },
};

export const DEFAULT_MILESTONE = {
  concluded: false,
  name: 'Default Milestone',
};

export const DEFAULT_KPI = {
  id: 0,
  emoji: '',
};

export const DEFAULT_FILTER_STATE = {
  filters: {
    priority: [
      DEFAULT_FILTER_TYPES.priority,
      { id: '2', name: 'Next' },
      { id: '3', name: 'Later' },
      { id: '4', name: 'Backlog' },
      { id: '5', name: 'Icebox' },
    ],
    status: [
      DEFAULT_FILTER_TYPES.status,
      { id: '2', name: 'On track' },
      { id: '3', name: 'Done' },
      { id: '4', name: 'Injected' },
      { id: '5', name: 'Deprioritized' },
      { id: '6', name: 'Delayed' },
      { id: '7', name: 'Blocked' },
    ],
    timeframe: [DEFAULT_FILTER_TYPES.timeframe],
    team: [DEFAULT_FILTER_TYPES.team],
    department: [DEFAULT_FILTER_TYPES.department],
  },
  currentView: VIEW_TYPES[0],
  selectedFilters: VIEW_TYPES.reduce(
    (acc, view) => (acc[view] = DEFAULT_FILTER_TYPES.selected) && acc,
    {} as Record<string, unknown>
  ),
  initiative: {
    title: '',
    description: '',
    teams: [],
    createdBy: 'System',
    assignees: [],
    jira: '',
    notes: '',
    kpis: [],
    timeframes: [],
    size: null,
    milestones: [],
  },
  quarter: {
    status: parseInt(DEFAULT_FILTER_TYPES.status.id),
    priority: parseInt(DEFAULT_FILTER_TYPES.priority.id),
    timeframe: DEFAULT_FILTER_TYPES.timeframe,
    status_message: '',
  },
  people: DEFAULT_FILTER_TYPES.people,
  loadingFilters: false,
  loadingInitiative: false,
  error: null,
};

export const DEFAULT_INITIATIVE_STATE = {
  roadmap: [],
  loading: false,
  error: null,
};

export const DEFAULT_APP_STATE = {
  deviceData: null,
  currentPage: null,
  showCreatePopup: false,
  showDeletePopup: false,
  authState: null,
  profile: {
    photo: ProfileImg,
  },
  error: null,
};

export const SERIALIZE_ALLOWED_KEYS = [
  'id',
  'title',
  'description',
  'createdBy',
  'updatedAt',
  'teams',
  'jira',
  'notes',
  'assignees',
  'size',
  'timeframes',
  'milestones',
  'tags',
  'fromDependencies',
  'toDependencies',
  // View Only Fields
  'kpis',
  'priority',
  'status',
  'status_message',
];

export const REQUIRED_KEYS = ['title', 'teams', 'timeframes'];

export const DESERIALIZE_ALLOWED_KEYS = [
  'title',
  'description',
  'createdBy',
  'teams',
  'jira',
  'notes',
  'assignees',
  'size',
  'timeframes',
  'position',
  'milestones',
  'tags',
  'kpis',
];

// enums
export enum EpicPriority {
  None,
  Now,
  Next,
  Later,
  Backlog,
}

export enum EpicStatus {
  None,
  Idea,
  'On Track',
  Done,
  Injected,
  Deprioritized,
  Delayed,
  Blocked,
}

export enum EpicSize {
  L = 'L',
  M = 'M',
  S = 'S',
  XL = 'XL',
  XS = 'XS',
}

export enum DependencyStatus {
  IDENTIFIED = 'IDENTIFIED',
  LINKED = 'LINKED',
  RESOLVED = 'RESOLVED',
}

export enum DependencyRelation {
  BLOCKS = 'BLOCKS',
  LINKED = 'LINKED',
  RESOLVED = 'RESOLVED',
}
