import { Grid, GridCol } from '@flixbus/honeycomb-react';
import React from 'react';

import { DepartmentTeamPicker } from './DepartmentTeamPicker';

import styles from './DependencyFilters.module.scss';

const DependencyFiltersComp: React.FC = () => {
  return (
    <div className={styles.container}>
      <Grid align={'center'}>
        <GridCol size={12}>
          <DepartmentTeamPicker />
        </GridCol>
      </Grid>
    </div>
  );
};

export const DependencyFilters = React.memo(DependencyFiltersComp);
