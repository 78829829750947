import React from 'react';
import { Heading, Grid, GridCol } from '@flixbus/honeycomb-react';
import styles from './Priorities.module.scss';

const Priorities = ({ priorities = [] }) => {
  return (
    <div className={styles.priorities}>
      <Grid align="bottom">
        {priorities.map((priority) => (
          <GridCol key={priority.id}>
            <Heading size={3}>{priority.name}</Heading>
          </GridCol>
        ))}
      </Grid>
    </div>
  );
};

export default Priorities;
