import {
  DataTable,
  Grid,
  GridCol,
  Icon,
  IconCheckmarkStrong,
  IconEye,
  IconLink,
  Link,
  Skeleton,
} from '@flixbus/honeycomb-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { DependencyStatus } from '../../../Constants';
import { useDependencyListQuery } from '../../../data/useDependencyListQuery';
import { useFiltersListQuery } from '../../../data/useFiltersListQuery';
import { getDependencyFilters } from '../../../selectors/appGlobals.selectors';
import { DependencyEpic, DependencyTeam } from '../../../types';

import styles from './DependencyTable.module.scss';

const headers = { cells: ['Team', '', 'Relation', 'Dependency On', '', 'Comment'] };

const DependencyTableComp: React.FC = () => {
  const dependencyFilters = useSelector(getDependencyFilters);
  const { isLoading: isFiltersLoading, error: filterError, data: filters } = useFiltersListQuery();
  const { isLoading: isDependencyLoading, error: dependencyError, data } = useDependencyListQuery(dependencyFilters);
  const isLoading = isFiltersLoading || isDependencyLoading;
  const error = dependencyError || filterError;

  if (!!error) {
    return <span>{error.message}</span>;
  }

  let rows = [
    {
      cells: [
        <span key="0">No dependencies found</span>,
        <span key="1" />,
        <span key="2" />,
        <span key="3" />,
        <span key="4" />,
        <span key="5" />,
      ],
      key: 'empty',
    },
  ];
  if (isLoading) {
    rows = ['1', '2', '3', '4'].map((key) => ({
      key,
      cells: [
        <Skeleton width="md" height="sm" key={0} />,
        <Skeleton width="md" height="sm" key={1} />,
        <Skeleton width="md" height="sm" key={2} />,
        <Skeleton width="md" height="sm" key={3} />,
        <Skeleton width="md" height="sm" key={4} />,
        <Skeleton width="md" height="sm" key={5} />,
      ],
    }));
  } else if (data && data.length > 0) {
    rows = data.map((dependency) => {
      const teamCell = (team: DependencyTeam, epic?: DependencyEpic) => (
        <div className={styles.overflowContainer}>
          <span className={styles.textContainer}>{team.name}</span>
          {epic && (
            <Link extraClasses={styles.textContainer} href={epic.link} target={'_blank'} title={epic.title}>
              {epic.title}
            </Link>
          )}
        </div>
      );

      const timeFramesCell = (epic?: DependencyEpic) => {
        if (!epic) {
          return <span />;
        }

        return (
          <Grid>
            {epic.timeframes.map((timeframe) => {
              const status = epic ? filters?.status[timeframe.status] : timeframe.status;
              return (
                <GridCol size={12} key={timeframe.timeframe.id}>
                  <span>
                    {timeframe.timeframe.name} - {status}
                  </span>
                </GridCol>
              );
            })}
          </Grid>
        );
      };
      const relationCell = () => {
        const relation = filters?.dependency_relation[dependency.relation] || dependency.relation;
        let icon = IconEye;
        if (dependency.status === DependencyStatus.LINKED) {
          icon = IconLink;
        } else if (dependency.status === DependencyStatus.RESOLVED) {
          icon = IconCheckmarkStrong;
        }

        return (
          <Grid>
            <GridCol size={12}>
              <span>{relation}</span>
            </GridCol>
            <GridCol size={12}>
              <Icon InlineIcon={icon} />
            </GridCol>
          </Grid>
        );
      };

      const commentCell = <span className={styles.commentContainer}>{dependency.comment || ''}</span>;

      return {
        key: dependency.id.toString(),
        cells: [
          teamCell(dependency.from_team, dependency.from_initiative),
          timeFramesCell(dependency.from_initiative),
          relationCell(),
          teamCell(dependency.to_team, dependency.to_initiative),
          timeFramesCell(dependency.to_initiative),
          commentCell,
        ],
      };
    });
  }

  return (
    <div>
      <DataTable headers={headers} rows={rows} extraClasses={styles.table} />
    </div>
  );
};

export const DependencyTable = React.memo(DependencyTableComp);
