import Axios, { AxiosInstance } from 'axios';
import React, { createContext, ReactNode, useMemo } from 'react';

import { APP_PAGES } from '../../app/Constants';

const baseAxios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AxiosContext = createContext<AxiosInstance>(baseAxios);

// Idea is to get rid of saga to make api calls and use axios / native fetch
export function AxiosProvider({ children, token }: { children?: ReactNode; token?: string }) {
  const axios = useMemo(() => {
    baseAxios.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    baseAxios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (401 === error.response.status) {
          window.location.pathname = APP_PAGES.login.path();
        } else {
          return Promise.reject(error);
        }
      }
    );

    return baseAxios;
  }, [token]);

  return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
}
