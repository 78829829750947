import { DependencyFilters } from '../types';

export const APP_GLOBALS_STORE_KEY = 'appGlobals';
export const APP_GLOBALS_DEPENDENCY_FILTERS_SET = 'APP_GLOBALS/DEPENDENCY_FILTERS_SET';

export type DependencyFiltersSetAction = {
  type: typeof APP_GLOBALS_DEPENDENCY_FILTERS_SET;
  payload: DependencyFilters;
};
export const dependencyFiltersSetAction = (payload: DependencyFilters): DependencyFiltersSetAction => ({
  type: APP_GLOBALS_DEPENDENCY_FILTERS_SET,
  payload,
});

export type AppGlobalsStateActions = DependencyFiltersSetAction;
