import { AllHtmlEntities } from 'html-entities';
import { DEFAULT_FILTER_TYPES, SERIALIZE_ALLOWED_KEYS } from '../Constants';
import { INITIATIVE_ARRAY_FIELDS } from './Constants';
import { Buffer } from 'buffer';

export const serializeInitiatives = (data) => data.map((initiative) => serializeInitiative(initiative));

export const bufferToBase64 = (data) => `data:image/jpeg;base64,${new Buffer(data, 'binary').toString('base64')}`;

export const trimIds = (objArray) =>
  objArray.map((item) => {
    const obj = { ...item };
    delete obj.id;

    return obj;
  });

export const serializeInitiative = (initiative) => {
  const serializedInitiative = Object.assign({}, initiative);
  serializedInitiative.notes = AllHtmlEntities.decode(initiative.notes);
  serializedInitiative.createdBy = initiative['created_by'];
  serializedInitiative.updatedAt = initiative['updated_at'];
  serializedInitiative.fromDependencies = initiative['from_dependencies'];
  serializedInitiative.toDependencies = initiative['to_dependencies'];
  serializedInitiative.timeframes = trimIds(initiative.timeframes || []);
  serializedInitiative.milestones = trimIds(initiative.milestones || []);
  serializedInitiative.size = initiative.size === undefined ? 'N/A' : initiative.size;

  for (const property in serializedInitiative) {
    if (!SERIALIZE_ALLOWED_KEYS.includes(property)) delete serializedInitiative[property];
  }

  serializedInitiative.match = true;

  return serializedInitiative;
};

export const parseInitiativeField = ({ field, value }) => {
  if (typeof value === 'string' && value.match(/^\d+$/)) value = parseInt(value);

  if (INITIATIVE_ARRAY_FIELDS.includes(field)) value = [value];

  return value;
};

export const parseSelectedFieldsIntoSession = (data, state) => {
  const {
    timeframe,
    team,
    department,
    toggles: { description },
  } = state.selectedFilters[state.currentView];

  const { timeframe: timeframes = [], team: teams = [], department: departments = [] } = data;

  teams.push(DEFAULT_FILTER_TYPES.team);

  return {
    timeframe: timeframes.find((item) => item.slug === timeframe) || timeframes[0] || DEFAULT_FILTER_TYPES.timeframe,
    team: teams.find((item) => item.id === team) || teams[0] || DEFAULT_FILTER_TYPES.team,
    department: departments.find((item) => item.id === department) || departments[0] || DEFAULT_FILTER_TYPES.department,
    description,
  };
};
